// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  hostname: 'https://dev.tanzschuhe24.de',
  stripe_pk: 'pk_test_YqmwADXw7jSWScfhB8EOa8eL',
  directus_url: 'https://directus.b2b.diamant.info/',
  sentry_dsn: 'https://fe19c3fb5ededcdbba7a891a439f217b@o1159139.ingest.us.sentry.io/4508065668071424',
  paypal_pui_merchant_id: '74ZJ8L3AZSU98',
};
