import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import {AppComponent} from './app.component';
import {LandingComponent} from './landing/landing.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {AgmCoreModule} from '@agm/core';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import {registerLocaleData} from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {UspSliderComponent} from './landing/usp-slider/usp-slider.component';
import {NgxJsonLdModule} from '@ngx-lite/json-ld';
import { AffiliateComponent } from './affiliate/affiliate.component';

registerLocaleData(localeDe, 'de');
registerLocaleData(localeEn, 'en');

@NgModule({
    declarations: [
        AppComponent,
        LandingComponent,
        NotFoundComponent,
        AffiliateComponent,
    ],
    imports: [
        BrowserModule,
        SharedModule,
        NgxJsonLdModule,
        AppRoutingModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBte993i4nOSv31mQsKsK7gwevYr_YPunA',
            libraries: ['places'],
            language: 'de'
        })
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: false,
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        {provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
