import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input() inputPath: string;
  private path: string;
  public html: SafeHtml;

  constructor(private http: HttpClient, private route: ActivatedRoute, private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    if (this.inputPath) {
      this.path = this.inputPath;
    } else {
      this.path = this.route.snapshot.data.path;
    }

    this.http.get('/static-pages/' + this.path + '.html', {responseType: 'text'})
      .subscribe(
        (html: string) => {
          this.html = this.transform(html);
        }
      );
  }

  transform(v: string) : SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(v);
  }

}
