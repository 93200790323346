import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';
import { routes, text } from '../shared/navbar/navbar.i18n';
import { ToastService } from '../shared/toast/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AffiliateService } from './affiliate.service';

@Component({
  selector: 'app-affiliate',
  templateUrl: './affiliate.component.html',
  styleUrls: ['./affiliate.component.scss']
})
export class AffiliateComponent implements OnInit {

  public route = routes;
  public text = {
    codeText: {
      de: 'Geben Sie Ihren Code ein',
      en: 'Please enter your code'
    },
    button: {
      de: 'Weiter',
      en: 'Continue'
    },
    alreadyEntered: {
      de: 'Sie haben bereits einen Code eingegeben, wenn Sie diesen ändern wollen, geben Sie den neuen Code ein und ' +
        'klicken Sie auf "Code ändern"',
      en: 'You have already entered a code, if you want to change it, enter the new code and then click on "Change code"'
    },
    affiliateText: {
      de: '[DE] Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus dolorum exercitationem fugiat ipsa libero minus neque ' +
        'obcaecati pariatur quam, quas reiciendis tenetur totam, veniam? Aperiam, aut consectetur culpa cumque deleniti doloribus eius ' +
        'eligendi expedita facere fugiat labore libero maxime minima nam obcaecati optio pariatur possimus, quo rem repellat, sunt' +
        ' suscipit totam? Enim eum harum ipsam magni maiores minus neque, nesciunt officiis optio qui soluta unde vel velit, voluptas,' +
        ' voluptatem. Accusantium animi blanditiis delectus dolorem doloribus ducimus expedita iste itaque laudantium necessitatibus, ' +
        'obcaecati officia quis reprehenderit, sit sunt ut velit. Accusamus accusantium ad dolorem dolores, itaque quae quasi ' +
        'quo reiciendis similique?',
      en: '[EN] Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab dolor dolores fugiat itaque molestias nemo pariatur ratione ' +
        'rem temporibus unde.'
    },
    affiliateTitle: {
      de: 'Dummy Deutsch',
      en: 'Dummy Englisch'
    },
    success: {
      de: 'Ihr Rabatt-Code wurde aktiviert.',
      en: 'Your Discount-Code is now activated.'
    },
    failure: {
      de: 'Ihr Rabatt-Code ist leider ungültig.',
      en: 'Your Discount-Code is invalid.'
    }
  };
  public affiliateCode = '';
  public storageCheck = false;
  public codeValid = false;

  private codeLookup$: Subject<void> = new Subject();

  constructor(public langS: LanguageService, private toast: ToastService, private rt: ActivatedRoute, private router: Router, private affiliateService: AffiliateService) {
  }

  async ngOnInit() {
    await this.checkUrlCode();
    this.checkStorage();

    this.codeLookup$
      .pipe(
        switchMap(() => {
          return this.affiliateService.checkAffiliateCode(this.affiliateCode).pipe(
            catchError(() => {
              return of({code: '', isValid: false});
            })
          );
        }),
      )
      .subscribe(res => {
        this.codeValid = res.isValid;
        if (this.codeValid) {
          this.affiliateCode = res.code;
        }
      });
  }

  async checkUrlCode() {
    if (this.rt.snapshot.params.code !== undefined) {
      this.affiliateCode = this.rt.snapshot.params.code;
      this.affiliateCode = this.affiliateCode.toUpperCase();
      this.codeValid = (await this.affiliateService.checkAffiliateCode(this.affiliateCode).toPromise()).isValid;
      if (this.codeValid) {
        this.storeAffiliateCode().then(() => {
          this.toast.spawnToast(this.text.success[this.langS.l]);
        });
      } else {
        this.toast.spawnToast(this.text.failure[this.langS.l]);
      }
    }
  }

  async storeAffiliateCode() {
    if (this.affiliateCode.length >= 4 && this.codeValid) {
      this.affiliateCode = this.affiliateCode.replace(/\s/g, '');
      localStorage.setItem('affiliate', this.affiliateCode);
      this.toast.spawnToast(this.text.success[this.langS.l]);
      await this.router.navigate([this.route.home[this.langS.l]]);
    }
  }

  checkStorage() {
    if (localStorage.getItem('affiliate') !== null) {
      this.storageCheck = true;
      this.affiliateCode = localStorage.getItem('affiliate');
      this.text.button.de = 'Code ändern';
      this.text.button.en = 'Change code';
    }
  }

  checkAffiliateCode(): void {
    this.affiliateCode = this.affiliateCode.toUpperCase();
    this.codeLookup$.next();
  }
}
