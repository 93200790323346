<nav class="navbar mt-0 d-flex justify-content-center py-4">
  <img src="https://res.cloudinary.com/tanzschuhe24-de/image/upload/f_auto/logo" width="250" height="auto" alt="">
</nav>

<div class="container mt-5 mb-4 pb-4 pt-5">
  <div class="d-flex justify-content-center align-items-center mt-3">
    <div class="justify-content-center flex-column input-form code-input-wrapper">
      <h2>{{text.codeText[langS.l]}}</h2>
      <p *ngIf="storageCheck">{{text.alreadyEntered[langS.l]}}</p>
      <input class="code-input w-100 form-control" type="text" [(ngModel)]="affiliateCode" (ngModelChange)="checkAffiliateCode()" (keyup.enter)="storeAffiliateCode()">
      <button class="btn btn-primary button mt-2 w-100" (click)="storeAffiliateCode()"
              [disabled]="affiliateCode?.length < 4 || !codeValid">{{text.button[langS.l]}}</button>
    </div>
  </div>
</div>

<!--<div class="container-fluid head-top pb-5 mb-5">-->
<!--  <div class="row d-flex affiliate-comp-height">-->
<!--    <div class="col-md-6 head-image d-none d-md-flex affiliate-comp-height">-->
<!--      <div class="head-image-content affiliate-comp-height">-->
<!--        <div class="head-image-background head-image-background-1"></div>-->
<!--        <div class="head-image-background head-image-background-2">-->
<!--          <div>-->
<!--            <p class="coupon-code">-5%</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-md-6 head-text d-flex">-->
<!--      <h2 class="title">{{text.affiliateTitle[langS.l]}}</h2>-->
<!--      <p class="text">{{text.affiliateText[langS.l]}}</p>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
