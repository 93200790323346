import { Injectable } from "@angular/core";
import { Product } from "../models/product";
import { CategoriesService } from "./shared/categories/categories.service";

declare let fbq;

@Injectable({
  providedIn: "root",
})
export class MetaService {
  constructor(private categoriesService: CategoriesService) {}

  addPageView() {
    fbq('track', 'PageView');
  }

  addViewContent(product: Product) {
    fbq("track", "ViewContent", {
      content_type: "product",
      content_category: this.categoriesService
        .getCategoryHierachy(product.categories)
        .join("/"),
      content_ids: [product.articleNumber],
      content_name: product.name,
    });
  }

  addToCart(product: Product) {
    fbq("track", "AddToCart", {
      content_category: this.categoriesService
        .getCategoryHierachy(product.categories)
        .join("/"),
      content_type: "product",
      content_name: product.name,
      contents: [{ id: product.articleNumber, quantity: product.amount ?? 1 }],
    });
  }

  initiateCheckout(products: Product[]) {
    fbq("track", "InitiateCheckout", {
      num_items: products.length,
      contents: products.map((p) => ({
        id: p.articleNumber,
        quantity: p.amount ?? 1,
      })),
      content_type: "product",
    });
  }

  purchase(products: Product[], revenue: number) {
    fbq("track", "Purchase", {
      currency: "EUR",
      value: revenue,
      content_type: "product",
      contents: products.map((p) => ({
        id: p.articleNumber,
        quantity: p.amount ?? 1,
      })),
    });
  }
}
