<app-usp-slider></app-usp-slider>

<div class="container mt-2">
  <div class="row categories-top d-flex mb-1 p-2 px-md-4 px-lg-3">
    <div class="category col-12 col-sm-6 mb-2 mb-md-0 pr-sm-1 pr-md-2">
      <a [routerLink]="ls.r(routes.cardLadies)" class="ladies category-select p-0 d-flex">
        <div class="name"><span>{{text.cardLadies | l}}</span></div>
      </a>
    </div>
    <div class="category col-12 col-sm-6 pl-sm-1 pl-md-2">
      <a [routerLink]="ls.r(routes.cardMens)" class="men category-select p-0 d-flex">
        <div class="name"><span>{{text.cardMens | l}}</span></div>
      </a>
    </div>
  </div>
  <div class="row categories-top d-flex mb-3 p-2 px-md-4 px-lg-3">
    <div class="category col-12 col-sm-6 mb-2 mb-md-0 pr-sm-1 pr-md-2">
      <a [routerLink]="ls.r(routes.cardLadiesDancewear)" class="ladies-dancewear category-select p-0 d-flex">
        <div class="name"><span>{{text.cardLadiesDancewear | l}}</span></div>
      </a>
    </div>
    <div class="category col-12 col-sm-6 pl-sm-1 pl-md-2">
      <a [routerLink]="ls.r(routes.cardMensDancewear)" class="mens-dancewear category-select p-0 d-flex">
        <div class="name"><span>{{text.cardMensDancewear | l}}</span></div>
      </a>
    </div>
  </div>
</div>

<div class="container-fluid head-top mt-5 mt-3">
  <div class="row d-flex">
    <div class="col-md-6 head-image d-none d-md-flex">
      <div class="head-image-content">
        <div class="head-image-background head-image-background-1"></div>
        <div class="head-image-background head-image-background-2" [routerLink]="ls.r(routes.headC2A)">
          <cl-image public-id="108-087-013/main" class="head-img" fetch_format="auto" [attr.alt]="text.headImg | l">
            <cl-transformation width="425" height="425" crop="pad"></cl-transformation>
          </cl-image>
        </div>
      </div>
    </div>
    <div class="col-md-6 head-text d-flex">
      <p class="top d-none d-md-block">{{text.headPreTitle | l}}</p>
      <h1 class="title" [innerHtml]="ls.t(text.headTitle)"></h1>
      <div class="text" [innerHtml]="text.headText | l"></div>
      <div class="w-100 text-left">
        <app-button [size]="'lg'" [outline]="true" class="mt-1" [link]="ls.r(routes.headC2A)" text="{{text.headC2A | l}}"></app-button>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="ls.l === 'de'">
  <div class="row mt-4 mx-0 store-info">
    <div class="col-md-7 description">
      <h2>Unser Ladengeschäft</h2>
      <p>Besuchen sie unser Geschäft in Bad Soden am Taunus und lassen sich professionell beraten.</p>
      <h5 class="my-0" style="font-weight: bold">Öffnungszeiten</h5>
      <dl *ngFor="let day of openingHours">
        <dt>{{day.weekday}}</dt>
        <dd>{{day.hour}}</dd>
      </dl>
    </div>
    <div class="col-md-5 mail-input goto-store text-left d-flex">
      <app-button text="Mehr Informationen" [link]="'ladengeschaeft'"></app-button>
    </div>
  </div>
</div>


<div class="container" *ngIf="showcaseProducts !== undefined && showcaseProducts.length > 0">
  <h2 class="mt-5">{{text.topsellerTitle | l}}</h2>
  <app-product-showcase
    [products]="[showcaseProducts[0], showcaseProducts[1], showcaseProducts[2], showcaseProducts[3], showcaseProducts[4], showcaseProducts[5]]"></app-product-showcase>
</div>



<div class="container" appVisible (onVisible)="showMap()">
  <div class="row mt-5">
    <div class="col-12">
      <h2>{{text.visitUsTitle | l}}</h2>
    </div>
    <div class="col-md-8" [innerHtml]="text.visitUsText[ls.l]">
    </div>
    <div class="col-12 pt-3">
      <agm-map *ngIf="mapVisible" [latitude]="lat" [longitude]="lng" [zoom]="18" [styles]="style" [streetViewControl]="false">
        <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
      </agm-map>

      <div class="placecard__container">
        <div class="placecard__left">
          <p class="placecard__business-name">Dance Art direct</p>
          <p class="placecard__info mb-0">Königsteiner Str. 22, 65812 Bad Soden am Taunus, Deutschland</p>
        </div>

        <div class="placecard__right">

          <a class="placecard__direction-link" href="https://maps.google.com/maps?ll=50.137597,8.509762&z=20&t=m&hl=de&gl=US&mapclient=embed&cid=6897722338554594201" target="_blank" rel="noopener">
            <app-icon>open_in_new</app-icon>
            Google&nbsp;Maps
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-12">
      <h2>{{text.moreInfoTitle | l}}</h2>
      <div [innerHtml]="text.moreInfoText | l"></div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row mt-5 mx-0 newsletter">
    <div class="col-md-7 description">
      <h2>{{text.newsletterTitle | l}}</h2>
      <p>{{text.newsletterText | l}}</p>
    </div>
    <div class="col-md-5 mail-input d-flex">
      <app-button text="{{text.newsletterInputC2A | l}}" [link]="ls.r(routes.highlights)"></app-button>
    </div>
  </div>
</div>

<ngx-json-ld [json]="orgaStructuredData"></ngx-json-ld>
<ngx-json-ld [json]="websiteStructuredData"></ngx-json-ld>
