import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { fromEvent, Subscription } from 'rxjs';
import { AppHammerConfig } from './../../app-hammer-config';

@Directive({
  selector: '[appZoomable]',
  exportAs: 'appZoomable',
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: AppHammerConfig
    }
  ]
})
export class ZoomableDirective implements OnDestroy, OnInit {

  @Input() eventElement;

  @Input() wheel = true;

  private hostSub: Subscription = null;

  moving = false;

  @Input() draggable = true;

  private lastDrag = { x: null, y: null };

  private draggingSub: Subscription = null;

  transformMatrix = [1, 0, 0, 1, 0, 0];

  prePinchMatrix = [1, 0, 0, 1, 0, 0];

  pinchOrigin = {
    x: 0,
    y: 0
  };

  boundaries: Element;

  @Input() scrollLock = true;
  private boundryHit = null;

  @Input() minScale = 1;
  @Input() maxScale = 5;

  @Output() position = new EventEmitter<{ x: number, y: number }>();

  constructor(public el: ElementRef, private renderer: Renderer2) {

    const value = '0px 0px 0px';

    this.renderer.setStyle(this.el.nativeElement, 'transform-origin', value);
    this.renderer.setStyle(this.el.nativeElement, '-webkit-transform-origin', value);
    this.renderer.setStyle(this.el.nativeElement, '-ms-transform-origin', value);
    this.renderer.setStyle(this.el.nativeElement, '-moz-transform-origin', value);
    this.renderer.setStyle(this.el.nativeElement, '-o-transform-origin', value);

    this.renderer.setStyle(this.el.nativeElement, 'transform-style', 'preserve-3d');
    this.renderer.setStyle(this.el.nativeElement, '-webkit-transform-style', 'preserve-3d');
    this.renderer.setStyle(this.el.nativeElement, '-ms-transform-style', 'preserve-3d');
    this.renderer.setStyle(this.el.nativeElement, '-moz-transform-style', 'preserve-3d');
    this.renderer.setStyle(this.el.nativeElement, '-o-transform-style', 'preserve-3d');

    // this.renderer.setStyle(this.el.nativeElement, 'will-change', 'transform');

    const helper = renderer.createElement('div');
    renderer.setStyle(helper, 'position', 'absolute');
    renderer.setStyle(helper, 'width', '100%');
    renderer.setStyle(helper, 'height', '100%');
    renderer.setStyle(helper, 'background-color', 'transparent');
    renderer.setStyle(helper, 'top', '0');
    renderer.setStyle(helper, 'left', '0');

    this.boundaries = helper;

    el.nativeElement.parentNode.insertBefore(helper, el.nativeElement);

    if (!this.eventElement) {
      this.eventElement = el.nativeElement;
    }

  }

  ngOnInit() {
    this.hostSub = fromEvent(this.eventElement, 'wheel', { passive: false }).subscribe(event => this.onwheel(event));
    this.hostSub.add(fromEvent(this.eventElement, 'mousedown', { passive: false }).subscribe(event => this.onMouseDown(event as MouseEvent)));
    this.hostSub.add(fromEvent(this.eventElement, 'touchstart', { passive: false }).subscribe(event => this.onMouseDown(event as TouchEvent)));
  }

  ngOnDestroy() {
    if (this.draggingSub) {
      this.draggingSub.unsubscribe();
    }
    if (this.hostSub) {
      this.hostSub.unsubscribe();
    }
  }


  private multiplyMatrix(a: number[], b: number[]): number[] {
    return [
      a[0] * b[0] + a[1] * b[3] + a[2] * b[6],
      a[0] * b[1] + a[1] * b[4] + a[2] * b[7],
      a[0] * b[2] + a[1] * b[5] + a[2] * b[8],

      a[3] * b[0] + a[4] * b[3] + a[5] * b[6],
      a[3] * b[1] + a[4] * b[4] + a[5] * b[7],
      a[3] * b[2] + a[4] * b[5] + a[5] * b[8],

      a[6] * b[0] + a[7] * b[3] + a[8] * b[6],
      a[6] * b[1] + a[7] * b[4] + a[8] * b[7],
      a[6] * b[2] + a[7] * b[5] + a[8] * b[8],
    ];
  }

  private multiplyVector(m: number[], v: number[]): number[] {

    return [
      m[0] * v[0] + m[1] * v[1] + m[2] * v[2],
      m[3] * v[0] + m[4] * v[1] + m[5] * v[2],
      m[6] * v[0] + m[7] * v[1] + m[8] * v[2],
    ];

  }

  // Geklaut von: http://blog.acipo.com/matrix-inversion-in-javascript/
  // Returns the inverse of matrix `M`.
  private matrix_invert(M): number[][] {
    // I use Guassian Elimination to calculate the inverse:
    // (1) 'augment' the matrix (left) by the identity (on the right)
    // (2) Turn the matrix on the left into the identity by elemetry row ops
    // (3) The matrix on the right is the inverse (was the identity matrix)
    // There are 3 elemtary row ops: (I combine b and c in my code)
    // (a) Swap 2 rows
    // (b) Multiply a row by a scalar
    // (c) Add 2 rows

    // if the matrix isn't square: exit (error)
    if (M.length !== M[0].length) {
      return;
    }

    // create the identity matrix (I), and a copy (C) of the original
    let i = 0, ii = 0, j = 0, e = 0;
    const dim = M.length, t = 0;
    const I = [], C = [];
    for (i = 0; i < dim; i += 1) {
      // Create the row
      I[I.length] = [];
      C[C.length] = [];
      for (j = 0; j < dim; j += 1) {

        // if we're on the diagonal, put a 1 (for identity)
        if (i == j) {
          I[i][j] = 1;
        } else {
          I[i][j] = 0;
        }

        // Also, make the copy of the original
        C[i][j] = M[i][j];
      }
    }

    // Perform elementary row operations
    for (i = 0; i < dim; i += 1) {
      // get the element e on the diagonal
      e = C[i][i];

      // if we have a 0 on the diagonal (we'll need to swap with a lower row)
      if (e == 0) {
        // look through every row below the i'th row
        for (ii = i + 1; ii < dim; ii += 1) {
          // if the ii'th row has a non-0 in the i'th col
          if (C[ii][i] != 0) {
            // it would make the diagonal have a non-0 so swap it
            for (j = 0; j < dim; j++) {
              e = C[i][j];       // temp store i'th row
              C[i][j] = C[ii][j]; // replace i'th row by ii'th
              C[ii][j] = e;      // repace ii'th by temp
              e = I[i][j];       // temp store i'th row
              I[i][j] = I[ii][j]; // replace i'th row by ii'th
              I[ii][j] = e;      // repace ii'th by temp
            }
            // don't bother checking other rows since we've swapped
            break;
          }
        }
        // get the new diagonal
        e = C[i][i];
        // if it's still 0, not invertable (error)
        if (e == 0) {
          return;
        }
      }

      // Scale this row down by e (so we have a 1 on the diagonal)
      for (j = 0; j < dim; j++) {
        C[i][j] = C[i][j] / e; // apply to original matrix
        I[i][j] = I[i][j] / e; // apply to identity
      }

      // Subtract this row (scaled appropriately for each row) from ALL of
      // the other rows so that there will be 0's in this column in the
      // rows above and below this one
      for (ii = 0; ii < dim; ii++) {
        // Only apply to other rows (we want a 1 on the diagonal)
        if (ii == i) {
          continue;
        }

        // We want to change this element to 0
        e = C[ii][i];

        // Subtract (the row above(or below) scaled by e) from (the
        // current row) but start at the i'th column and assume all the
        // stuff left of diagonal is 0 (which it should be if we made this
        // algorithm correctly)
        for (j = 0; j < dim; j++) {
          C[ii][j] -= e * C[i][j]; // apply to original matrix
          I[ii][j] -= e * I[i][j]; // apply to identity
        }
      }
    }

    // we've done all operations, C should be the identity
    // matrix I should be the inverse:
    return I;
  }

  private enforceBoundaries(m: number[]): number[] {
    const translate = [1, 0, 0, 0, 1, 0, 0, 0, 1];

    if (m[2] > 0) {
      translate[2] = 0 - m[2];
    }
    if (m[5] > 0) {
      m[5] = 0 - m[5];
    }

    const b: any = this.boundaries;

    const newBottomRight = this.multiplyVector(m, [b.offsetWidth, b.offsetHeight, 1]);

    if (newBottomRight[0] < b.offsetWidth) {
      translate[2] = b.offsetWidth - newBottomRight[0];
    }

    if (newBottomRight[1] < b.offsetHeight) {
      translate[5] = b.offsetHeight - newBottomRight[1];
    }

    return this.multiplyMatrix(translate, m);
  }

  move(deltaX: number, deltaY: number, transformMatrix, boundaries?: boolean) {
    const trans = [1, 0, deltaX, 0, 1, deltaY, 0, 0, 1];

    const t = transformMatrix;

    const oldTransform = [t[0], t[2], t[4], t[1], t[3], t[5]].concat([0, 0, 1]);

    let newTransform = this.multiplyMatrix(oldTransform, trans);


    // check if borders are inside boundary
    if (boundaries) {
      const temp = newTransform[5];
      newTransform = this.enforceBoundaries(newTransform);

      // if first move directly hits boundry put back
      if (!this.scrollLock && this.boundryHit === null) {
        if (newTransform[5] - temp !== 0) {
          this.putBack();
        } else {
          this.boundryHit = newTransform[5] - temp;
        }
      }
    }


    this.transformMatrix = [
      newTransform[0],
      newTransform[3],
      newTransform[1],
      newTransform[4],
      newTransform[2],
      newTransform[5]
    ];

    this.setTransform(this.transformMatrix);


  }

  zoom(s: number, x: number, y: number, transformMatrix: number[], boundaries?: boolean) {
    const trans = [1, 0, x, 0, 1, y, 0, 0, 1];
    const backTrans = [1, 0, -x, 0, 1, -y, 0, 0, 1];

    if (transformMatrix[0] * s > this.maxScale) {
      s = this.maxScale / transformMatrix[0];
    }

    const scale = [s, 0, 0, 0, s, 0, 0, 0, 1];

    const t = transformMatrix;

    const oldTransform = [t[0], t[2], t[4], t[1], t[3], t[5]].concat([0, 0, 1]);

    let newTransform = this.multiplyMatrix(
      this.multiplyMatrix(
        this.multiplyMatrix(
          oldTransform, trans), scale), backTrans);

    if (newTransform[0] < this.minScale && newTransform[4] < this.minScale) {
      this.deZoom();
      return;
    }

    // check if borders are inside boundary
    if (boundaries) {
      newTransform = this.enforceBoundaries(newTransform);
    }

    this.transformMatrix = [
      newTransform[0],
      newTransform[3],
      newTransform[1],
      newTransform[4],
      newTransform[2],
      newTransform[5]
    ];

    this.setTransform(this.transformMatrix);

  }

  setTransform(m: number[]) {
    const value = 'matrix(' +
      m[0] + ',' +
      m[1] + ',' +
      m[2] + ',' +
      m[3] + ',' +
      m[4] + ',' +
      m[5] +
      ')';

    this.renderer.setStyle(this.el.nativeElement, 'transform', value);
    this.renderer.setStyle(this.el.nativeElement, '-webkit-transform', value);
    this.renderer.setStyle(this.el.nativeElement, '-ms-transform', value);
    this.renderer.setStyle(this.el.nativeElement, '-moz-transform', value);
    this.renderer.setStyle(this.el.nativeElement, '-o-transform', value);
  }

  isZoom() {
    return !(this.transformMatrix[0] === 1 &&
      this.transformMatrix[1] === 0 &&
      this.transformMatrix[2] === 0 &&
      this.transformMatrix[3] === 1 &&
      this.transformMatrix[4] === 0 &&
      this.transformMatrix[5] === 0);
  }

  deZoom() {
    this.transformMatrix = [1, 0, 0, 1, 0, 0];
    this.prePinchMatrix = [1, 0, 0, 1, 0, 0];

    this.setTransform(this.transformMatrix);
  }

  private subscribeDragEvents() {
    this.draggingSub = fromEvent(document, 'mousemove', { passive: false }).subscribe(event => this.onMouseMove(event as MouseEvent));
    this.draggingSub.add(fromEvent(document, 'touchmove', { passive: false }).subscribe(event => this.onMouseMove(event as TouchEvent)));
    this.draggingSub.add(fromEvent(document, 'mouseup', { passive: false }).subscribe(() => this.putBack()));
    // checking if browser is IE or Edge - https://github.com/xieziyu/angular2-draggable/issues/153
    const isIEOrEdge = /msie\s|trident\//i.test(window.navigator.userAgent);
    if (!isIEOrEdge) {
      this.draggingSub.add(fromEvent(document, 'mouseleave', { passive: false }).subscribe(() => this.putBack()));
    }
    this.draggingSub.add(fromEvent(document, 'touchend', { passive: false }).subscribe(() => this.putBack()));
    this.draggingSub.add(fromEvent(document, 'touchcancel', { passive: false }).subscribe(() => this.putBack()));
  }

  private unsubscribeEvents() {
    this.draggingSub.unsubscribe();
    this.draggingSub = null;
  }


  onMouseMove(event: MouseEvent | TouchEvent) {
    if (this.moving && this.draggable) {

      event.stopPropagation();
      event.preventDefault();

      const pos = { x: undefined, y: undefined };

      if (event instanceof MouseEvent) {
        pos.x = event.clientX;
        pos.y = event.clientY;
      } else if (event instanceof TouchEvent) {
        pos.x = event.changedTouches[0].clientX;
        pos.y = event.changedTouches[0].clientY;
      }

      if (this.lastDrag.x !== undefined && this.lastDrag.x !== null) {

        const deltaX = (pos.x - this.lastDrag.x) / this.transformMatrix[0];
        const deltaY = (pos.y - this.lastDrag.y) / this.transformMatrix[0];

        this.move(deltaX, deltaY, this.transformMatrix, true);
      }

      this.lastDrag.x = pos.x;
      this.lastDrag.y = pos.y;
    }
  }

  putBack() {
    if (this.moving) {
      this.moving = false;
      this.lastDrag.x = null;
      this.lastDrag.y = null;
      this.boundryHit = null;
      this.unsubscribeEvents();
    }
  }

  pickUp() {
    if (!this.moving) {
      this.moving = true;
      this.subscribeDragEvents();
    }

  }

  onMouseDown(event: MouseEvent | TouchEvent) {
    if (event instanceof MouseEvent && event.button === 2) {
      return;
    }

    if (this.isZoom()) {
      this.pickUp();
    }
  }

  onwheel(event) {
    if (this.wheel) {
      event.preventDefault();
      event.stopPropagation();

      let s = 1;

      if (event.deltaY > 0) {
        s = 1.1;
      } else if (event.deltaY < 0) {
        s = 0.9;
      } else if (event.deltaY === 0) {
        s = 1;
      }

      let x = event.layerX;
      let y = event.layerY;

      if (event.offsetX) {
        x = event.offsetX;
        y = event.offsetY;
      }

      this.zoom(s, x, y, this.transformMatrix, true);
    }
  }


  onPinchStart(event) {
    this.draggable = false;
    const rect = this.el.nativeElement.getBoundingClientRect();
    const t = this.transformMatrix;
    const m = [
      [t[0], t[2], t[4]],
      [t[1], t[3], t[5]],
      [0, 0, 1]
    ];

    const i = this.matrix_invert(m);

    const leftBottom = this.multiplyVector(
      i[0].concat(i[1]).concat(i[2]),
      [rect.left, rect.top, 1]
    );

    let x = event.center.x - leftBottom[0];
    let y = event.center.y - leftBottom[1];

    const newPoint = this.multiplyVector(
      i[0].concat(i[1]).concat(i[2]),
      [x, y, 1]
    );

    x = newPoint[0];
    y = newPoint[1];

    this.prePinchMatrix = [].concat(this.transformMatrix);
    this.pinchOrigin = { x, y };
  }

  onPinch(event) {
    this.zoom(event.scale, this.pinchOrigin.x, this.pinchOrigin.y, this.prePinchMatrix, true);
  }

  onPinchEnd() {
    this.draggable = true;
    this.prePinchMatrix = [].concat(this.transformMatrix);
  }

}
