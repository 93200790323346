import { Component, Input } from '@angular/core';
import { LanguageService } from '../../language.service';
import { FilterService } from '../../search/filter/filter.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {

  @Input() filterContent: string[];
  @Input() type: string;

  private colorMapping = {
    'blau-grau': ['blue', 'grey'],
    'blue-grey': ['blue', 'grey'],
    'silber': ['silver'],
    'silver': ['silver'],
    'schwarz': ['black'],
    'black': ['black'],
    'schwarz-grau': ['black', 'grey'],
    'black-grey': ['black', 'grey'],
    'schwarz-weiß': ['black', 'white'],
    'black-white': ['black', 'white'],
    'braun': ['brown'],
    'brown': ['brown'],
    'weiß': ['white'],
    'white': ['white'],
    'gold': ['gold'],
    'bronze': ['bronze'],
    'schwarz-silber': ['black', 'silver'],
    'black-silver': ['black', 'silver'],
    'schwarz-rot': ['black', 'red'],
    'black-red': ['black', 'red'],
    'beige': ['beige']
  };

  colorCodes = {
    'blue': '#0000FF',
    'grey': '#808080',
    'silver': '#C0C0C0',
    'black': '#000000',
    'brown': '#8B4513',
    'white': '#FFFFFF',
    'gold': '#ffbf00',
    'bronze': '#CD853F',
    'red': '#a4121b',
    'beige': '#F5F5DC'
  };

  constructor(public ls: LanguageService, public fs: FilterService) {
  }

  getColors(color: string): string[] {
    return this.colorMapping[color?.toLowerCase()];
  }

}
