import { AfterViewInit, Component, Input } from "@angular/core";
import { Product } from "../../../models/product";
import { AnalyticsService } from "../../analytics.service";

@Component({
  selector: "app-product-showcase",
  templateUrl: "./product-showcase.component.html",
  styleUrls: ["./product-showcase.component.scss"],
})
export class ProductShowcaseComponent implements AfterViewInit {
  @Input() products: Product[];

  constructor(private analyticsService: AnalyticsService) {}

  ngAfterViewInit() {
    this.analyticsService.addItemViewInList(this.products);
  }
}
