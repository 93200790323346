import { Injectable } from "@angular/core";
import { LanguageService } from "../../language.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, mergeMap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { AffiliateService } from "../../affiliate/affiliate.service";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  isLoggedIn = false;
  // Workaround für den verkackten IE.
  private options = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    }),
  };

  constructor(
    private http: HttpClient,
    private ls: LanguageService,
    private as: AffiliateService
  ) {}

  localLogin(email: string, password: string) {
    const body: any = {
      email: email,
      password: password,
    };

    return this.http.post("/auth/local/login", body, this.options);
  }

  localSignup(email: string, password: string) {
    const body: any = {
      email: email,
      password: password,
      lang: this.ls.l,
    };

    return this.http.post("/auth/local/signup", body, this.options);
  }

  guestLogin(guest_id, guest_pw) {
    return this.http.post(
      "/auth/guest/login",
      { guest_id, password: guest_pw },
      this.options
    );
  }

  guestSignup(email: string) {
    return this.http.post("/auth/guest/signup", { email: email }, this.options);
  }

  getAccountInfo() {
    return this.http.get("/auth/account", this.options).pipe(
      mergeMap((res: any) => {
        if (res.status === "not logged in") {
          this.isLoggedIn = false;
          return of(undefined);
        } else {
          this.isLoggedIn = true;
          if (this.as.codeActive && !res.discountCode) {
            return this.setDiscountCode();
          } else {
            return of(res);
          }
        }
      })
    );
  }

  setDiscountCode(): Observable<any> {
    const affiliateCode = localStorage.getItem("affiliate");
    if (affiliateCode) {
      return this.http.post(`/auth/affiliate-code/apply`, {
        code: affiliateCode,
      });
    }
    return of({ success: false });
  }

  logout() {
    this.isLoggedIn = false;
    return this.http.get("/auth/logout", this.options);
  }
}
