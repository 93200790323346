<div class="trust-bar d-none d-sm-flex d-print-none" *ngIf="!os.categoriesOverlay">
  <a [routerLink]="routes.returnsAndShipping[langS.l]">
    <div class="trust-bar-inner">
      <span><app-icon>business</app-icon>&nbsp;{{text.returnPolicy[langS.l]}}</span>
      <span class="d-none d-md-inline-flex"><app-icon>timer</app-icon>&nbsp;{{text.madeInGermany[langS.l]}}</span>
      <span class="d-none d-lg-inline-flex"><app-icon>build</app-icon>&nbsp;{{text.repairService[langS.l]}}</span>
      <span><app-icon>local_shipping</app-icon>&nbsp;{{text.freeReturns[langS.l]}}</span>
    </div>
  </a>
</div>

<div class="mobile-wrapper d-lg-none d-print-none">
  <div class="navbar navbar--mobile">
    <div class="left" *ngIf="!ns.showSearch">
      <div class="categories icon" (click)="os.toggleCategoriesOverlay()">
        <app-icon *ngIf="!os.categoriesOverlay">menu</app-icon>
        <app-icon *ngIf="os.categoriesOverlay">close</app-icon>
      </div>
    </div>
    <div class="center" *ngIf="!ns.showSearch">
      <a [routerLink]=routes.home[langS.l]>
        <img class="logo" alt="tanzschuhe24 Logo" src="https://res.cloudinary.com/tanzschuhe24-de/image/upload/f_auto/logo">
      </a>
    </div>
    <div class="right">
      <div class="search-bar" *ngIf="ns.showSearch">
        <app-searchbar></app-searchbar>
      </div>

      <div class="search" *ngIf="!ns.showSearch">
        <div class="icon search-icon" (click)="ns.activateSearch(true)">
          <app-icon>search</app-icon>
        </div>
      </div>

      <div class="redeem" *ngIf="!ns.showSearch && affiliateService.codeActive">
        <div class="icon cart-icon">
          <app-icon [ngbTooltip]="text.affiliateCode[langS.l]">redeem</app-icon>
        </div>
      </div>

      <div class="cart" [routerLink]="routes.cart[langS.l]" *ngIf="!ns.showSearch">
        <div class="icon cart-icon">
          <app-icon>shopping_cart</app-icon>
          <div class="count" *ngIf="cs.productCount > 0"><p class="number">{{cs.productCount}}</p></div>
        </div>
      </div>
    </div>
  </div>
  <app-loading-bar></app-loading-bar>
</div>
<div class="navbar navbar--desktop d-none d-lg-flex d-print-none">
  <div class="navbar-inner d-flex">
    <div class="top d-flex">
      <div class="left">
        <div class="super-category-nav">
          <ul role="tablist" class="nav">
            <li class="nav-item">
              <a class="nav-link" [routerLink]="langS.rl + routes.ladies.root[langS.l]" [routerLinkActive]="['active']"
                 [ngClass]="{'last-active': catS.getActiveMainCategory() == 'Damen'}"
                 #damen>{{text.ladies[langS.l]}}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="langS.rl + routes.mens.root[langS.l]" [routerLinkActive]="['active']"
                 [ngClass]="{'last-active': catS.getActiveMainCategory() == 'Herren'}" #herren>{{text.men[langS.l]}}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="langS.rl + routes.children.root[langS.l]"
                 [routerLinkActive]="['active']"
                 [ngClass]="{'last-active': catS.getActiveMainCategory() == 'Kinder'}"
                 #kinder>{{text.children[langS.l]}}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="center">
        <a [routerLink]=routes.home[langS.l]>
          <img class="logo" alt="tanzschuhe24 Logo" src="https://res.cloudinary.com/tanzschuhe24-de/image/upload/f_auto/logo">
        </a>
      </div>

      <div class="right">
        <div class="search-bar" *ngIf="ns.showSearch">
          <app-searchbar></app-searchbar>
        </div>

        <div class="search" *ngIf="!ns.showSearch">
          <div class="icon search-icon" (click)="ns.activateSearch(true)">
            <app-icon>search</app-icon>
          </div>
        </div>

        <div class="account" [routerLink]="getAccountRouting()" *ngIf="!ns.showSearch">
          <div class="icon account-icon">
            <app-icon>account_circle</app-icon>
          </div>
        </div>

        <div class="redeem" *ngIf="!ns.showSearch && affiliateService.codeActive">
          <div class="icon cart-icon">
            <app-icon [ngbTooltip]="text.affiliateCode[langS.l]">redeem</app-icon>
          </div>
        </div>

        <div class="cart" [routerLink]="routes.cart[langS.l]" *ngIf="!ns.showSearch">
          <div class="icon cart-icon">
            <app-icon>shopping_cart</app-icon>
            <div class="count" *ngIf="cs.productCount > 0"><p class="number">{{cs.productCount}}</p></div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom d-flex">
      <div class="left">
        <div class="categories-links">
          <ul *ngIf="catS.getActiveMainCategory() === 'Damen'">
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.latin[langS.l]"
                   [routerLinkActive]="['active']">{{text.latin[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.standard[langS.l]"
                   [routerLinkActive]="['active']">{{text.standard[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.showdance[langS.l]"
                   [routerLinkActive]="['active']">{{text.showdance[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.trainer[langS.l]"
                   [routerLinkActive]="['active']">{{text.trainer[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.wedding[langS.l]"
                   [routerLinkActive]="['active']">{{text.bridal[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.variospin[langS.l]"
                   [routerLinkActive]="['active']">{{text.variospin[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.dancewear[langS.l]"
                   [routerLinkActive]="['active']">{{text.dancewear[langS.l]}}</a></li>
<!--            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.comfy[langS.l]"-->
<!--                   [routerLinkActive]="['active']">{{text.comfy[langS.l]}}</a></li>-->
<!--            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.festive[langS.l]"-->
<!--                   [routerLinkActive]="['active']">{{text.festive[langS.l]}}</a></li>-->
<!--            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.black[langS.l]"-->
<!--                   [routerLinkActive]="['active']">{{text.black[langS.l]}}</a></li>-->
<!--            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.ladies.silver[langS.l]"-->
<!--                   [routerLinkActive]="['active']">{{text.silver[langS.l]}}</a></li>-->
          </ul>

          <ul *ngIf="catS.getActiveMainCategory() === 'Herren'">
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.mens.standard[langS.l]"
                   [routerLinkActive]="['active']">{{text.standard[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.mens.latin[langS.l]"
                   [routerLinkActive]="['active']">{{text.latin[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.mens.tango[langS.l]"
                   [routerLinkActive]="['active']">{{text.tango[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.mens.ballroom[langS.l]"
                   [routerLinkActive]="['active']">{{text.ballroom[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.mens.wedding[langS.l]"
                   [routerLinkActive]="['active']">{{text.wedding[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.mens.variospin[langS.l]"
                   [routerLinkActive]="['active']">{{text.variospin[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.mens.dancewear[langS.l]"
                   [routerLinkActive]="['active']">{{text.dancewear[langS.l]}}</a></li>
<!--            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.mens.casual[langS.l]"-->
<!--                   [routerLinkActive]="['active']">{{text.casual[langS.l]}}</a></li>-->
<!--            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.mens.comfy[langS.l]"-->
<!--                   [routerLinkActive]="['active']">{{text.comfy[langS.l]}}</a></li>-->
<!--            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.mens.wide[langS.l]"-->
<!--                   [routerLinkActive]="['active']">{{text.wide[langS.l]}}</a></li>-->
          </ul>

          <ul *ngIf="catS.getActiveMainCategory() === 'Kinder'">
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.children.boys[langS.l]"
                   [routerLinkActive]="['active']">{{text.boys[langS.l]}}</a></li>
            <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.children.girls[langS.l]"
                   [routerLinkActive]="['active']">{{text.girls[langS.l]}}</a></li>
          </ul>

          <span *ngIf="!isIE()">|</span>

          <span *ngIf="!isIE()"><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.misc.variopro[langS.l]"
                                   [routerLinkActive]="['active']">{{text.variopro[langS.l]}}</a></span>
          <span *ngIf="!isIE()"><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.misc.accessories[langS.l]"
                                   [routerLinkActive]="['active']">{{text.accessories[langS.l]}}</a></span>
          <!-- <span *ngIf="!isIE()"><a (click)="os.hideCategoriesOverlay()" class="sale" [routerLink]="langS.rl + routes.sale[langS.l]"
                                   [routerLinkActive]="['active']">{{text.sale[langS.l]}}</a></span> -->
          <!-- <span *ngIf="!isIE()"><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.new[langS.l]"
                                   [routerLinkActive]="['active']">{{text.new[langS.l]}}</a></span> -->
          <!-- <span *ngIf="!isIE()"><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.materials[langS.l]"
                                   [routerLinkActive]="['active']">{{text.materials[langS.l]}}<sup class="nav-link-label align-middle badge badge-warning">{{text.new[langS.l]}}</sup></a></span> -->


         <span *ngIf="!isIE()"><a (click)="os.hideCategoriesOverlay()" [routerLink]="langS.rl + routes.misc.clutches[langS.l]"
                                  [routerLinkActive]="['active']">{{text.clutches[langS.l]}}<sup
                                        class="nav-link-label align-middle badge badge-warning">{{text.new[langS.l]}}</sup></a></span>
        </div>
      </div>
      <div class="center">
      </div>
      <div class="right">
        <app-language-picker></app-language-picker>
      </div>
    </div>
  </div>
</div>

<app-overlay class="d-lg-none"></app-overlay>
