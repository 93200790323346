import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { LanguageService } from "../../language.service";
import { AnalyticsService } from "../../analytics.service";
import { text } from "./item.i18n";
import { ImageViewService } from "../../search/product/image-view/image-view.service";

@Component({
  selector: "app-item",
  templateUrl: "./item.component.html",
  styleUrls: ["./item.component.scss"],
  providers: [ImageViewService],
})
export class ItemComponent implements OnInit {
  @Input() content: any;
  public enteredViewport = false;
  private params: any;
  productStatus: any;
  public text = text;

  get devicePixelRatio(): number {
    return this.ivs.getDevicePixelRatio();
  }

  get isMaterial() {
    return this.content?.categories[0] === "Z10_MAT";
  }

  get isDancewear() {
    return (this.content?.categories[0] === "TK_D" || this.content?.categories[0] === "TK_H");
  }

  get isNonShoeArticle() {
    return this.isMaterial || this.isAccesory || this.isDancewear;
  }

  constructor(
    private route: ActivatedRoute,
    public ls: LanguageService,
    public as: AnalyticsService,
    private ivs: ImageViewService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => (this.params = params["term"])
    );
  }

  loadImage() {
    this.enteredViewport = true;
  }

  click() {
    this.as.clickOnItem(this.content);
  }

  get getShortDescription() {
    if (this.content.properties && this.content.properties.heelType) {
      if (this.content.categories.includes("H01")) {
        return this.content.properties.width[this.ls.l];
      } else {
        return this.content.properties.heelType[this.ls.l];
      }
    } else {
      return "";
    }
  }

  get getLink() {
    let descPart = this.content.title[this.ls.l].replace(/\s+/g, "-");
    descPart = descPart.replace("/", "");
    descPart = descPart.replace("(", "");
    descPart = descPart.replace(")", "");
    // descPart = descPart.replace('.', '');
    descPart = descPart.toLowerCase();

    return `${this.getPrefix(this.content.categories)}${
      this.getProductNumber
    }/${descPart}`;
  }

  get getProductNumber() {
    return this.content.articleNumber;
  }

  get isAccesory() {
    return this.content.categories[0] === "Z01";
  }

  // TODO: Use categories service
  private getPrefix(categories) {
    const de = this.ls.l !== "en";

    // Children
    if (categories.includes("K01")) {
      return de ? "/kinder/" : "/en/children/";
    }

    // Determine Super
    let superCategory = "/";
    if (categories.includes("H01")) {
      superCategory = de ? "/herren" : "/en/mens";
    }
    if (categories.includes("D01")) {
      superCategory = de ? "/damen" : "/en/ladies";
    }

    // Determine Subcategory
    let subCategory = "/";
    if (categories.includes("D04_Tango") || categories.includes("H04_Tango")) {
      subCategory = "/tango";
    }
    if (categories.includes("D06_Trai")) {
      subCategory = "/trainer";
    }
    if (
      categories.includes("D02_Latein") ||
      categories.includes("H02_Latein")
    ) {
      subCategory = de ? "/latein" : "/latin";
    }
    if (
      categories.includes("D03_Standa") ||
      categories.includes("H03_Standa")
    ) {
      subCategory = "/standard";
    }
    if (categories.includes("H06_Ballro")) {
      subCategory = "/ballroom-sneaker";
    }

    // Check if all went fine
    if (superCategory === "/" || subCategory === "/") {
      return de ? "/artikel/" : "/en/article/";
    }

    return superCategory + subCategory + "/";
  }
}
