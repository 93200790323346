import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from './button/button.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { FooterComponent } from './footer/footer.component';
import { IconComponent } from './icon/icon.component';
import { LanguageSwitcherComponent } from './language-switcher/language-switcher.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ProductBarComponent } from './product-bar/product-bar.component';
import { ProductShowcaseComponent } from './product-showcase/product-showcase.component';
import { ToastComponent } from './toast/toast.component';
import { OverlayComponent } from './navbar/overlay/overlay.component';
import { SearchbarComponent } from './navbar/searchbar/searchbar.component';
import { RouterModule } from '@angular/router';
import { LanguagePipe } from '../language.pipe';
import { ItemComponent } from './item/item.component';
import { CategoriesComponent } from './categories/categories.component';
import { clodinaryConfig } from '../../cloudinaryconfig';
import { CloudinaryModule } from '@cloudinary/angular-5.x';
import { Cloudinary } from 'cloudinary-core/cloudinary-core-shrinkwrap';
import { LoaderComponent } from './loader/loader.component';
import { LanguagePickerComponent } from './navbar/language-picker/language-picker.component';
import { VisibleDirective } from '../visible.directive';
import { PaypalExpressButtonComponent } from './paypal-express-button/paypal-express-button.component';
import { LoadingBarComponent } from './navbar/loading-bar/loading-bar.component';
import { ZoomableDirective } from './zoomable/zoomable.directive';
import {
  NgbDropdownModule,
  NgbTypeaheadModule,
  NgbProgressbarModule,
  NgbTabsetModule,
  NgbPopoverModule,
  NgbTooltipModule, NgbNavModule
} from '@ng-bootstrap/ng-bootstrap';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { UspSliderComponent } from '../landing/usp-slider/usp-slider.component';

const cloudinaryLib = {
  Cloudinary: Cloudinary
};

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgbDropdownModule,
    NgbTypeaheadModule,
    NgbProgressbarModule,
    NgbTabsetModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgbNavModule,
    CloudinaryModule.forRoot(cloudinaryLib, clodinaryConfig),
  ],
  declarations: [
    ButtonComponent,
    DropdownComponent,
    FooterComponent,
    IconComponent,
    LanguageSwitcherComponent,
    NavbarComponent,
    ProductBarComponent,
    ProductShowcaseComponent,
    ToastComponent,
    OverlayComponent,
    SearchbarComponent,
    LanguagePipe,
    LanguagePickerComponent,
    ItemComponent,
    CategoriesComponent,
    LoaderComponent,
    VisibleDirective,
    PaypalExpressButtonComponent,
    LoadingBarComponent,
    ZoomableDirective,
    ScrollToTopComponent,
    UspSliderComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    CloudinaryModule,
    ButtonComponent,
    DropdownComponent,
    FooterComponent,
    IconComponent,
    LanguageSwitcherComponent,
    LanguagePickerComponent,
    NavbarComponent,
    NgbTabsetModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgbNavModule,
    ProductBarComponent,
    ProductShowcaseComponent,
    ToastComponent,
    OverlayComponent,
    SearchbarComponent,
    LanguagePipe,
    ItemComponent,
    CategoriesComponent,
    LoaderComponent,
    VisibleDirective,
    PaypalExpressButtonComponent,
    ZoomableDirective,
    ScrollToTopComponent,
    UspSliderComponent
  ],
})
export class SharedModule { }
