import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {OverlayService} from '../overlay/overlay.service';
import {LanguageService} from '../../../language.service';
import {text} from './searchbar.i18n';
import {NavbarService} from '../navbar.service';
import {CategoriesService} from '../../categories/categories.service';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit {

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  public searchTerm = '';
  private autocompleted = false;

  public text = text;

  constructor(public cas: CategoriesService, public router: Router, public os: OverlayService, public ls: LanguageService, public ns: NavbarService) {
    router.events.subscribe((val) => {
      this.clearSearch();
    });
  }

  ngOnInit() {
    if (this.ns.focusSearch) {
      this.searchInput.nativeElement.focus();
    }
  }

  navigate(newTerm?: string) {
    this.autocompleted = true;

    this.searchInput.nativeElement.blur();

    let tempTerm = this.searchTerm;
    if (newTerm !== undefined) {
      tempTerm = newTerm;
    }
    this.os.hideCategoriesOverlay();
    this.router.navigate([this.cas.generateLink(tempTerm)]);
  }

  searchFor(term: string) {
    setTimeout(() => {
        if (!this.autocompleted) {
          this.searchInput.nativeElement.blur();

          this.os.hideCategoriesOverlay();
          this.router.navigate([this.cas.generateLink(term)]);
        }
      },
      50);
  }

  isFocused() {
    return this.searchInput.nativeElement === document.activeElement;
  }

  clearSearch() {
    this.searchTerm = '';
    this.autocompleted = false;
  }

  public search = (text$: Observable<string>) =>
    text$
      .pipe(
        debounceTime(50),
        distinctUntilChanged(),
        map(term => term.length < 2 ? []
        : this.cas.categories[this.ls.l].filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
      )
}
