<div class="pre-footer container-fluid d-print-none">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-6 col-lg-4 mb-4 mb-lg-0 item methods">
        <h4>{{text.paymentOptions[ls.l]}}</h4>
        <div class="options d-flex">
          <div class="option">
            <cl-image public-id="mastercard" fetch_format="auto" [attr.alt]="'Zahlung mit mastercard'">
              <cl-transformation width="60" height="35" crop="pad"></cl-transformation>
            </cl-image>
          </div>
          <div class="option">
            <cl-image public-id="visa" fetch_format="auto" [attr.alt]="'Bezahlen mit Visa'">
              <cl-transformation width="60" height="35" crop="pad"></cl-transformation>
            </cl-image>
          </div>
          <div class="option">
            <cl-image public-id="paypal" fetch_format="auto" [attr.alt]="'Einkauf über PayPal'">
              <cl-transformation width="60" height="35" crop="pad"></cl-transformation>
            </cl-image>
          </div>
          <div class="option pia">
            {{text.pia[ls.l]}}
          </div>
          <div class="option pia">
            {{text.rech[ls.l]}}
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-lg-4 mb-4 mb-lg-0 item methods">
        <h4>{{text.shippingServices[ls.l]}}</h4>
        <div class="options d-flex">
          <div class="option">
            <cl-image public-id="dhl" fetch_format="auto" [attr.alt]="'Versand per DHL'">
              <cl-transformation width="60" height="35" crop="pad"></cl-transformation>
            </cl-image>
          </div>

          <div class="option">
            <cl-image public-id="dpd" fetch_format="auto" [attr.alt]="'Versand per dpd'">
              <cl-transformation width="60" height="35" crop="pad"></cl-transformation>
            </cl-image>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-4 item">
        <h4>{{text.service[ls.l]}}</h4>
        <div class="service d-flex">
          <!--<div #googleBadge></div>-->
          <a class="mb-1" href="https://www.diamant.net" target="_blank" rel="noopener">
            <cl-image [public-id]="'Diamant-Online-Zertifikat/main'" fetch_format="auto"
              [attr.alt]="'Diamant zertifizierter Online-Shop'">
              <cl-transformation width="auto" height="65" crop="pad"></cl-transformation>
            </cl-image>
          </a>
          <span>
            <app-icon>timer</app-icon>&nbsp;{{text.madeInGermany[ls.l]}}
          </span>
          <span>
            <app-icon>business</app-icon>&nbsp;{{text.returnPolicy[ls.l]}}
          </span>
          <span>
            <app-icon>local_shipping</app-icon>&nbsp;{{text.freeReturns[ls.l]}}
          </span>
          <span>*{{text.disclaimerShipping[ls.l]}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer container-fluid">
  <div class="container">
    <div class="row d-print-none">
      <div class="d-none d-sm-block col-sm-4 main-footer">
        <h5>{{text.contact[ls.l]}}</h5>
        <ul>
          <li>
            <div class="contact-pair">
              <p>{{text.phone[ls.l]}}</p>
              <a href="tel:+496196594544">+49-(0)6196-594544</a>
            </div>
          </li>
          <li>
            <div class="contact-pair">
              <p>{{text.email[ls.l]}}</p>
              <a href="mailto:info@tanzschuhe24.de">info@tanzschuhe24.de</a>
            </div>
          </li>
        </ul>
      </div>

      <div class="col-6 col-sm-4">
        <h5>{{text.information[ls.l]}}</h5>
        <ul>
          <li>
            <a [routerLink]="routes.i18nVersion[ls.l]"
              (click)="ls.saveLang(routes.i18nLang[ls.l])">{{text.i18nVersion[ls.l]}}</a>
          </li>
          <li>
            <a [routerLink]="ls.rl + '/blog'">
              Blog
            </a>
          </li>
          <li>
            <a [routerLink]="ls.rl + routes.danceShoes[ls.l]">
              {{text.danceShoes[ls.l]}}
            </a>
          </li>
          <li>
            <a [routerLink]="'/ladengeschaeft'" title="Ladengeschäft" *ngIf="ls.l === 'de'">
              Ladengeschäft
            </a>
          </li>
          <li>
            <a [routerLink]="ls.rl + routes.faq[ls.l]" title="Häufige Fragen">
              {{text.faq[ls.l]}}
            </a>
          </li>
          <li>
            <a [routerLink]="ls.rl + routes.footWidth[ls.l]" title="Fußweiten">
              {{text.footWidth[ls.l]}}
            </a>
          </li>
          <li>
            <a [routerLink]="ls.rl + routes.lexiconOfDances[ls.l]" title="Tanzlexikon">
              {{text.lexiconOfDances[ls.l]}}
            </a>
          </li>
        </ul>
      </div>

      <div class="col-6 col-sm-4">
        <h5>tanzschuhe24</h5>
        <ul role="menu">
          <li role="menuitem">
            <a [routerLink]="ls.rl + routes.rightOfWithdrawl[ls.l]" title="Widerrufsrecht">
              {{text.rightOfWithdrawal[ls.l]}}
            </a>
          </li>
          <li role="menuitem">
            <a [routerLink]="ls.rl + routes.returnsAndShippingCosts[ls.l]" title="Rücksendungen & Versandkosten">
              {{text.returnsAndShippingCosts[ls.l]}}
            </a>
          </li>
          <li role="menuitem">
            <a [routerLink]="ls.rl + routes.termsConditions[ls.l]" title="AGB">
              {{text.termsConditions[ls.l]}}
            </a>
          </li>
          <li role="menuitem">
            <a [routerLink]="ls.rl + routes.privacy[ls.l]" title="Datenschutz">
              {{text.privacy[ls.l]}}
            </a>
          </li>
          <li role="menuitem">
            <a [routerLink]="ls.rl + routes.imprint[ls.l]" title="Impressum">
              {{text.imprint[ls.l]}}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <hr class="divider d-print-none">

    <div class="sub-footer">
      <p>{{text.disclaimerA[ls.l]}}<a
          [routerLink]="ls.rl + routes.returnsAndShippingCosts[ls.l]"><b>{{text.disclaimerLink[ls.l]}}</b></a>{{text.disclaimerB[ls.l]}}
      </p>
      <p class="mt-2 mt-md-0">{{text.disclaimerC[ls.l]}}</p>

      <div class="other-icons d-flex justify-content-center mt-3">
        <a class="mx-2" href="https://www.instagram.com/tanzschuhe24.de/" rel="noopener" target="_blank">
          <app-icon [type]="'social'">instagram</app-icon>
        </a>
        <a class="mx-2" href="https://www.facebook.com/tanzschuhe24" rel="noopener" target="_blank">
          <app-icon [type]="'social'">facebook</app-icon>
        </a>
        <a class="mx-2" href="https://twitter.com/tanzschuhe24_de" rel="noopener" target="_blank">
          <app-icon [type]="'social'">twitter</app-icon>
        </a>
      </div>
    </div>
  </div>
</div>
