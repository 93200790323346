<div class="categories" [ngClass]="{'hide': (os.categoriesOverlay && os.hideOverlay)}">
  <div class="categories-main">
    <hr class="divider top">
    <ul role="tablist" class="nav">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="ls.rl + routes.ladies.root[ls.l]" [routerLinkActive]="['active']"
           [ngClass]="{'last-active': cs.getActiveMainCategory() == 'Damen'}" #damen>{{text.ladies[ls.l]}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="ls.rl + routes.mens.root[ls.l]" [routerLinkActive]="['active']"
           [ngClass]="{'last-active': cs.getActiveMainCategory() == 'Herren'}" #herren>{{text.men[ls.l]}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="ls.rl + routes.children.root[ls.l]" [routerLinkActive]="['active']"
           [ngClass]="{'last-active': cs.getActiveMainCategory() == 'Kinder'}" #kinder>{{text.children[ls.l]}}</a>
      </li>
    </ul>

    <div class="categories-links">
      <ul *ngIf="cs.getActiveMainCategory() === 'Damen'">
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.latin[ls.l]"
               [routerLinkActive]="['active']">{{text.latin[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.standard[ls.l]"
               [routerLinkActive]="['active']">{{text.standard[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.showdance[ls.l]"
               [routerLinkActive]="['active']">{{text.showdance[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.trainer[ls.l]"
               [routerLinkActive]="['active']">{{text.trainer[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.wedding[ls.l]"
               [routerLinkActive]="['active']">{{text.bridal[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.variospin[ls.l]"
               [routerLinkActive]="['active']">{{text.variospin[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.dancewear[ls.l]"
               [routerLinkActive]="['active']">{{text.dancewear[ls.l]}}</a></li>
<!--        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.comfy[ls.l]"-->
<!--               [routerLinkActive]="['active']">{{text.comfy[ls.l]}}</a></li>-->
<!--        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.festive[ls.l]"-->
<!--               [routerLinkActive]="['active']">{{text.festive[ls.l]}}</a></li>-->
<!--        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.black[ls.l]"-->
<!--               [routerLinkActive]="['active']">{{text.black[ls.l]}}</a></li>-->
<!--        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.silver[ls.l]"-->
<!--               [routerLinkActive]="['active']">{{text.silver[ls.l]}}</a></li>-->
      </ul>

      <ul *ngIf="cs.getActiveMainCategory() === 'Herren'">
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.mens.latin[ls.l]"
               [routerLinkActive]="['active']">{{text.latin[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.mens.standard[ls.l]"
               [routerLinkActive]="['active']">{{text.standard[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.mens.tango[ls.l]"
               [routerLinkActive]="['active']">{{text.tango[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.mens.ballroom[ls.l]"
               [routerLinkActive]="['active']">{{text.ballroom[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.mens.wedding[ls.l]"
               [routerLinkActive]="['active']">{{text.wedding[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.mens.variospin[ls.l]"
               [routerLinkActive]="['active']">{{text.variospin[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.mens.dancewear[ls.l]"
               [routerLinkActive]="['active']">{{text.dancewear[ls.l]}}</a></li>
<!--        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.mens.casual[ls.l]"-->
<!--               [routerLinkActive]="['active']">{{text.casual[ls.l]}}</a></li>-->
<!--        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.mens.comfy[ls.l]"-->
<!--               [routerLinkActive]="['active']">{{text.comfy[ls.l]}}</a></li>-->
<!--        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.mens.wide[ls.l]"-->
<!--               [routerLinkActive]="['active']">{{text.wide[ls.l]}}</a></li>-->
      </ul>

      <ul *ngIf="cs.getActiveMainCategory() === 'Kinder'">
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.children.boys[ls.l]"
               [routerLinkActive]="['active']">{{text.boys[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.children.girls[ls.l]"
               [routerLinkActive]="['active']">{{text.girls[ls.l]}}</a></li>
      </ul>

      <div class="divider-wrapper">
        <div class="divider"></div>
        <span>{{text.misc[ls.l]}}</span>
        <div class="divider"></div>
      </div>
      <p><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + 'variopro'"
            [routerLinkActive]="['active']">VarioPro</a></p>
      <!-- <p><a (click)="os.hideCategoriesOverlay()" class="sale" [routerLink]="ls.rl + routes.misc.sale[ls.l]"
            [routerLinkActive]="['active']">{{text.sale[ls.l]}}</a></p> -->
      <!-- <p><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.misc.new[ls.l]"
            [routerLinkActive]="['active']">{{text.new[ls.l]}}</a></p> -->
      <p><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.misc.accessories[ls.l]"
            [routerLinkActive]="['active']">{{text.accessories[ls.l]}}</a></p>
       <!-- <p><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.misc.materials[ls.l]"
              [routerLinkActive]="['active']">{{text.materials[ls.l]}}<sup class="link-label align-middle badge badge-warning">{{text.new | l}}</sup></a></p> -->
       <p><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.misc.clutches[ls.l]"
              [routerLinkActive]="['active']">{{text.clutches[ls.l]}}<sup class="link-label align-middle badge badge-warning">{{text.new | l}}</sup></a></p>
    </div>
  </div>
  <div class="extra-nav-mobile">
    <div class="navigation">
      <div class="account d-flex" [routerLink]="ls.rl + routes.account[ls.l]" (click)="os.hideCategoriesOverlay()">
        {{text.account | l}}
        <app-icon class="ml-1">account_circle</app-icon>
      </div>
      <div class="language">
        <app-language-picker></app-language-picker>
      </div>
    </div>

<!--    <div class="trust-bar d-sm-none">-->
<!--      <div class="trust-bar-inner">-->
<!--        <span><app-icon>timer</app-icon>&nbsp;{{text.madeInGermany | l}}</span>-->
<!--        <span><app-icon>business</app-icon>&nbsp;{{text.returnPolicy | l}}</span>-->
<!--        <span><app-icon>local_shipping</app-icon>&nbsp;{{text.freeReturns | l}}</span>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
