import {Component, OnInit, ViewChild} from '@angular/core';
import {LanguageService} from '../../language.service';
import {routes, text} from './footer.i18n';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  public text = text;
  public routes = routes;

  @ViewChild('googleBadge') googleBadge;

  constructor(public ls: LanguageService) {
  }

  ngOnInit() {
    // this.googleBadge.nativeElement.innerHTML = '<g:ratingbadge merchant_id="453727"></g:ratingbadge>​';
  }

}
