import {Injectable} from '@angular/core';
import {Cloudinary} from '@cloudinary/angular-5.x';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ImageViewService {
  constructor(private http: HttpClient, private cloudinary: Cloudinary) {
  }


  getProductImages(productId: string) {
    return this.http.get('/api/img/folder/' + productId);
  }

  getDevicePixelRatio(): number {
    return window?.devicePixelRatio ?? 1;
  }
}
