import {Component, OnInit} from '@angular/core';
import {CartService} from '../../cart/cart.service';
import {LoginService} from '../../user/login/login.service';
import {OverlayService} from './overlay/overlay.service';
import {LanguageService} from '../../language.service';
import {routes, text} from './navbar.i18n';
import {NavbarService} from './navbar.service';
import {CategoriesService} from '../categories/categories.service';
import { AffiliateService } from '../../affiliate/affiliate.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public routes = routes;
  public text = text;
  private account: any;
  private error: any;

  constructor(public catS: CategoriesService, public cs: CartService, public ls: LoginService, public os: OverlayService, public langS: LanguageService, public ns: NavbarService, public affiliateService: AffiliateService) {
  }

  ngOnInit() {
    this.ls.getAccountInfo()
      .subscribe(
        account => this.account = account,
        error => this.error = error
      );
  }

  isLoggedIn() {
    return this.account !== undefined;
  }

  getAccountRouting() {
    if (this.isLoggedIn()) {
      return this.langS.rl + 'dashboard';
    } else {
      return this.langS.rl + 'login';
    }
  }

  public isIE() {
    const userAgent = navigator.userAgent;
    return userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1;
  }
}
