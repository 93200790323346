import {Component, OnInit, ViewChild} from '@angular/core';
import {ToastService} from './toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

  @ViewChild('timeIndicator') timeIndicator;

  constructor(public ts: ToastService) {
  }

  ngOnInit() {
  }

}
