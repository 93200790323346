import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../language.service';
import {routes, text} from './language-switcher.i18n';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {

  public text = text;
  public routes = routes;

  constructor(public l: LanguageService) { }

  ngOnInit() {
  }

}
