export const text = {
  search: {
    de: 'Suchbegriff',
    en: 'Search'
  },
  searchCategories: {
    de: 'Kategorien & Suche',
    en: 'Categories & Search'
  }
};
