import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

import * as Sentry from "@sentry/angular";
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: environment.sentry_dsn,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", environment.hostname],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  ignoreErrors: ['ExpressionChangedAfterItHasBeenCheckedError'],
  tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
