import { Injectable } from "@angular/core";
import { CategoriesService } from "./shared/categories/categories.service";
import { Product } from "../models/product";
import { environment } from "../environments/environment";
import { MetaService } from "./meta.service";

declare let gtag;

interface Item {
  item_id: string;
  item_name: string;
  item_category: string;
  price: number;
  quantity?: number;
}

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  constructor(private categoriesService: CategoriesService, private metaService: MetaService) {}

  addPageView(url: string) {
    if (!this.isPrerender()) {
      this.sendEvent("config", "G-SKKCZ08TT9", {
        page_path: url,
      });
      this.metaService.addPageView();
    }
  }

  addItemViewInList(products: Product[]) {
    if (!this.isPrerender()) {
      this.sendEvent("event", "view_item_list", {
        items: products.map((p) =>
          this.gtagAddProduct(p, { includeQuantity: false })
        ),
      });
    }
  }

  clickOnItem(product: Product) {
    this.sendEvent("event", "select_item", {
      items: [this.gtagAddProduct(product, { includeQuantity: false })],
    });
  }

  addItemView(product: Product) {
    if (!this.isPrerender()) {
      this.sendEvent("event", "view_item", {
        items: [this.gtagAddProduct(product, { includeQuantity: false })],
      });
      this.metaService.addViewContent(product);
    }
  }

  addToCart(product: Product) {
    this.sendEvent("event", "add_to_cart", {
      items: [this.gtagAddProduct(product)],
      value: product.price,
      currency: "EUR",
    });
    this.metaService.addToCart(product);
  }

  removeFromCart(product: Product) {
    this.sendEvent("event", "remove_from_cart", {
      items: [this.gtagAddProduct(product)],
      value: product.price,
      currency: "EUR",
    });
  }

  initiateCheckout(products: Product[], revenue: number) {
    this.sendEvent("event", "begin_checkout", {
      items: products.map((p) => this.gtagAddProduct(p)),
      currency: "EUR",
      value: revenue,
    });
    this.metaService.initiateCheckout(products);
  }

  addPaymentInfo(productValue: number, method: string, products: Product[]) {
    this.sendEvent("event", "add_payment_info", {
      currency: "EUR",
      value: productValue,
      payment_type: method,
      items: products.map((p) => this.gtagAddProduct(p)),
    });
  }

  purchase(
    products: Product[],
    orderNum: string,
    revenue: number,
    shipping: number,
    tax: number
  ) {
    this.sendEvent("event", "purchase", {
      transaction_id: orderNum,
      value: revenue,
      currency: "EUR",
      shipping: shipping,
      tax: tax ?? 0,
      items: products.map((p) => this.gtagAddProduct(p)),
    });
    this.metaService.purchase(products, revenue);
  }

  checkoutFailed() {
    this.sendEvent("event", "failed_checkout", {});
  }

  private gtagAddProduct(
    product: Product,
    options = { includeQuantity: true }
  ): Item {
    const item: Item = {
      item_id: product.articleNumber,
      item_name: product.name,
      price: product.price,
      item_category: this.categoriesService
        .getCategoryHierachy(product.categories)
        .join("/"),
    };
    if (options.includeQuantity) {
      item.quantity = product.amount ?? 1;
    }

    return item;
  }

  private isPrerender() {
    return /Prerender/i.test(navigator.userAgent);
  }

  private sendEvent(
    type: string,
    eventName: string,
    params: Record<string, any>
  ) {
    this.addTrafficType(params);
    gtag(type, eventName, params);
  }

  private addTrafficType(params: Record<string, any>) {
    if (environment.production) {
      params["traffic_type"] = "production";
    } else {
      params["traffic_type"] = "development";
    }
  }
}
