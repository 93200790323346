import { Injectable } from "@angular/core";
import DirectusSDK from "@directus/sdk-js";
import { environment } from "../environments/environment";
import { IConfiguration } from "@directus/sdk-js/dist/types/Configuration";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BlogPost } from "./shared/interfaces/directus/blog-post.interface";
import { QueryParams } from "@directus/sdk-js/dist/types/schemes/http/Query";
import { LanguageService } from "./language.service";
import { DomSanitizer } from "@angular/platform-browser";
import { Page, Status } from "./shared/interfaces/directus/enums";
import { OpeningHours } from "./shared/interfaces/directus/opening-hours.interface";
import { InfoPageTranslation } from "./shared/interfaces/directus/translations.interface";
import { keyBy } from "lodash-es";

@Injectable({
  providedIn: "root",
})
export class DirectusService {
  client = new DirectusSDK({
    url: environment.directus_url,
    project: "diamant",
  } as IConfiguration);

  private POST_LIMIT = 5;

  constructor(
    private languageService: LanguageService,
    private sanitizer: DomSanitizer
  ) {}

  private loadContent<T>(
    collection: string,
    options?: QueryParams
  ): Observable<T> {
    return from(this.client.getItems(collection, options)).pipe(
      map((res: any) => res.data),
      map((data) =>
        data.map((dataObj) => {
          const translations = keyBy(
            dataObj.translations,
            (itm) => itm.language
          );

          return { ...dataObj, translations };
        })
      ),
      map((data) =>
        data.map((item) => {
          const translation = item.translations[this.languageService.l] ?? item.translations["de"];

          delete translation.id;
          delete translation.language;
          delete translation.created_on;
          delete translation.modified_on;
          delete translation.modified_by;

          delete item.translations;

          const translatedItem = {
            ...item,
            ...translation,
            url: translation.url ?? null,
            content: translation.content
            ? this.sanitizer.bypassSecurityTrustHtml(translation.content)
            : null,
            image: item.image ? item.image.data.full_url : null,
          };

          return translatedItem;
        })
      )
    );
  }

  loadOpeningTimes(): Observable<OpeningHours[]> {
    return from(this.client.getItems<OpeningHours[]>("opening_hours")).pipe(
      map((res) => res.data)
    );
  }

  loadBlogPosts(offset?: number): Observable<BlogPost[]> {
    return this.loadContent<BlogPost[]>("blog_post", {
      sort: "-publication_date",
      fields: "*.*",
      filter: {
        shop: { eq: Page.TS24 },
        status: { eq: Status.PUBLISHED },
      },
      limit: this.POST_LIMIT,
      offset: offset ?? 0,
    });
  }

  loadBlogPost(slug: string): Observable<BlogPost> {
    return this.loadContent<BlogPost>("blog_post", {
      fields: "*.*",
      filter: {
        "translations.slug": { eq: slug },
        shop: { eq: Page.TS24 },
        status: { eq: Status.PUBLISHED },
      },
    }).pipe(map((p) => p[0]));
  }

  loadDynamicPage(slug: string): Observable<InfoPageTranslation> {
    return this.loadContent("info_page", {
      fields: "*.*",
      filter: {
        "translations.slug": { eq: slug },
      },
    }).pipe(map((p) => p[0]));
  }
}
