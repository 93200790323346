const countries = {
  de: {
    de: "Deutschland",
    en: "Germany",
    europe: true,
    eu: true,
  },
  at: {
    de: "Österreich",
    en: "Austria",
    europe: true,
    eu: true,
  },
  ch: {
    de: "Schweiz",
    en: "Switzerland",
    europe: true,
    eu: false,
  },
  nl: {
    de: "Niederlande",
    en: "Netherlands",
    europe: true,
    eu: true,
  },
  be: {
    de: "Belgien",
    en: "Belgium",
    europe: true,
    eu: true,
  },
  dk: {
    de: "Dänemark",
    en: "Denmark",
    europe: true,
    eu: true,
  },
  fr: {
    de: "Frankreich",
    en: "France",
    europe: true,
    eu: true,
  },
  us: {
    de: "USA",
    en: "USA",
    europe: false,
    eu: false,
  },
  fi: {
    de: "Finnland",
    en: "Finland",
    europe: true,
    eu: true,
  },
  el: {
    de: "Griechenland",
    en: "Greece",
    europe: true,
    eu: true,
  },
  uk: {
    de: "Vereinigtes Königreich",
    en: "United Kingdom",
    europe: true,
    eu: true,
  },
  country: {
    de: "Irland",
    en: "Ireland",
    europe: true,
    eu: true,
  },
  is: {
    de: "Island",
    en: "Iceland",
    europe: true,
    eu: false,
  },
  it: {
    de: "Italien",
    en: "Italy",
    europe: true,
    eu: true,
  },
  hr: {
    de: "Kroatien",
    en: "Croatia",
    europe: true,
    eu: true,
  },
  li: {
    de: "Liechtenstein",
    en: "Liechtenstein",
    europe: true,
    eu: false,
  },
  lu: {
    de: "Luxemburg",
    en: "Luxembourg",
    europe: true,
    eu: true,
  },
  no: {
    de: "Norwegen",
    en: "Norway",
    europe: true,
    eu: false,
  },
  pl: {
    de: "Polen",
    en: "Poland",
    europe: true,
    eu: true,
  },
  pt: {
    de: "Portugal",
    en: "Portugal",
    europe: true,
    eu: true,
  },
  ro: {
    de: "Rumänien",
    en: "Romania",
    europe: true,
    eu: true,
  },
  se: {
    de: "Schweden",
    en: "Sweden",
    europe: true,
    eu: true,
  },
  sk: {
    de: "Slowakei",
    en: "Slovakia",
    europe: true,
    eu: true,
  },
  es: {
    de: "Spanien",
    en: "Spain",
    europe: true,
    eu: true,
  },
  cz: {
    de: "Tschechische Republik",
    en: "Czech Republic",
    europe: true,
    eu: true,
  },
  hu: {
    de: "Ungarn",
    en: "Hungary",
    europe: true,
    eu: true,
  },
  au: {
    de: "Australien",
    en: "Australia",
    europe: false,
    eu: false,
  },
  br: {
    de: "Brasilien",
    en: "Brazil",
    europe: false,
    eu: false,
  },
  bg: {
    de: "Bulgarien",
    en: "Bulgaria",
    europe: true,
    eu: true,
  },
  ee: {
    de: "Estland",
    en: "Estonia",
    europe: true,
    eu: true,
  },
  hk: {
    de: "Hongkong",
    en: "Hong Kong",
    europe: false,
    eu: false,
  },
  il: {
    de: "Israel",
    en: "Israel",
    europe: false,
    eu: false,
  },
  jp: {
    de: "Japan",
    en: "Japan",
    europe: false,
    eu: false,
  },
  ca: {
    de: "Kanada",
    en: "Canada",
    europe: false,
    eu: false,
  },
  lv: {
    de: "Lettland",
    en: "Latvia",
    europe: true,
    eu: true,
  },
  lt: {
    de: "Litauen",
    en: "Lithuania",
    europe: true,
    eu: true,
  },
  mt: {
    de: "Malta",
    en: "Malta",
    europe: true,
    eu: true,
  },
  mk: {
    de: "Mazedonien",
    en: "Macedonia",
    europe: true,
    eu: false,
  },
  nz: {
    de: "Neuseeland",
    en: "New Zealand",
    europe: false,
    eu: false,
  },
  ru: {
    de: "Rußland",
    en: "Russia",
    europe: true,
    eu: true,
  },
  sg: {
    de: "Singapur",
    en: "Singapore",
    europe: false,
    eu: false,
  },
  sl: {
    de: "Slowenien",
    en: "Slovenia",
    europe: true,
    eu: true,
  },
  tr: {
    de: "Türkei",
    en: "Turkey",
    europe: true,
    eu: false,
  },
  cy: {
    de: "Zypern",
    en: "Cyprus",
    europe: true,
    eu: true,
  },
  eg: {
    de: "Ägypten",
    en: "Egypt",
    europe: false,
    eu: false,
  },
  by: {
    de: "Weißrussland",
    en: "Belarus",
    europe: true,
    eu: false,
  },
  ae: {
    de: "Vereinigte Arabische Emirate",
    en: "United Arab Emirates",
    europe: false,
    eu: false,
  },
  do_: {
    de: "Dominikanische Republik",
    en: "Dominican Republic",
    europe: false,
    eu: false,
  },
  ec: {
    de: "Ecuador",
    en: "Ecuador",
    europe: false,
    eu: false,
  },
  kz: {
    de: "Kasachstan",
    en: "Kazakhstan",
    europe: true,
    eu: false,
  },
  my: {
    de: "Malaysia",
    en: "Malaysia",
    europe: false,
    eu: false,
  },
  mx: {
    de: "Mexico",
    en: "Mexico",
    europe: false,
    eu: false,
  },
  md: {
    de: "Moldawien",
    en: "Moldova",
    europe: true,
    eu: false,
  },
  pk: {
    de: "Pakistan",
    en: "Pakistan",
    europe: false,
    eu: false,
  },
  ph: {
    de: "Philippinen",
    en: "Philippines",
    europe: false,
    eu: false,
  },
  sy: {
    de: "Seychellen",
    en: "Seychelles",
    europe: false,
    eu: false,
  },
  za: {
    de: "Südafrika",
    en: "South Africa",
    europe: false,
    eu: false,
  },
  ua: {
    de: "Ukraine",
    en: "Ukraine",
    europe: true,
    eu: false,
  },
};

export default countries;