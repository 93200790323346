import {HammerGestureConfig} from '@angular/platform-browser';
import {Injectable} from '@angular/core';

declare let Hammer;
@Injectable()
export class AppHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    pinch: {enabled: false},
    rotate: {enabled: false},
    pan: {
      direction: 6
    },
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
    touchAction: 'pan-y'
  };
  options = {
    recognizers: [
      [Hammer.Pan, {direction: Hammer.DIRECTION_VERTICAL}],
    ],
  };
}
