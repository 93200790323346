export const text = {
    returnPolicy: {
        de: 'Kostenlose Retoure möglich*',
        en: 'Free Returns possible (DE)*'
    },
    madeInGermany: {
        de: 'Versand binnen 24h',
        en: 'Shipping within 24h'
    },
    freeReturns: {
        de: 'Konstenloser Versand ab 80€*',
        en: 'Free shipping from €80 (DE)*'
    },
    freeRepairService: {
        de: 'Kostenloser Reparaturservice*',
        en: 'Free Repairservice*'
    }
};

export const routes = {
    returnsAndShippingCosts: {
        de: 'ruecksendungen-und-versandkosten',
        en: 'returns-and-shipping-costs',
    }
};
