import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RoutesRecognized} from '@angular/router';
import {AnalyticsService} from './analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public disNavbar = true;
  constructor(public router: Router, private as: AnalyticsService, private rt: ActivatedRoute) {
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        this.as.addPageView(evt.urlAfterRedirects);
      }
      if (evt instanceof RoutesRecognized) {
        this.disNavbar = evt.state.root.firstChild.data.navbar === undefined;
      }
    });
  }

}
