import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LandingComponent} from './landing/landing.component';
import {LoaderComponent} from './shared/loader/loader.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {AffiliateComponent} from './affiliate/affiliate.component';

const routes: Routes = [
  // GERMAN
  // basic
  {path: '', component: LandingComponent},
  {path: 'a', component: AffiliateComponent, data: {navbar: false}},
  {path: 'a/:code', component: AffiliateComponent, data: {navbar: false}},

  // info
  {path: 'ladengeschaeft', loadChildren: () => import('./store/store.module').then(m => m.StoreModule)},
  {path: 'haeufige-fragen', component: LoaderComponent, data: {path: 'faq'}},
  {path: 'fussweiten', component: LoaderComponent, data: {path: 'fussweiten'}},
  {path: 'tanzlexikon', component: LoaderComponent, data: {path: 'tanzlexikon'}},
  {path: 'tanzlexikon/langsamer-walzer', component: LoaderComponent, data: {path: 'tanzlexikon/langsamer-walzer'}},
  {path: 'tanzlexikon/wiener-walzer', component: LoaderComponent, data: {path: 'tanzlexikon/wiener-walzer'}},
  {path: 'tanzlexikon/tango', component: LoaderComponent, data: {path: 'tanzlexikon/tango'}},
  {path: 'tanzlexikon/slowfox', component: LoaderComponent, data: {path: 'tanzlexikon/slowfox'}},
  {path: 'tanzlexikon/quickstep', component: LoaderComponent, data: {path: 'tanzlexikon/quickstep'}},
  {path: 'tanzlexikon/rumba', component: LoaderComponent, data: {path: 'tanzlexikon/rumba'}},
  {path: 'tanzlexikon/samba', component: LoaderComponent, data: {path: 'tanzlexikon/samba'}},
  {path: 'tanzlexikon/cha-cha-cha', component: LoaderComponent, data: {path: 'tanzlexikon/cha-cha-cha'}},
  {path: 'tanzlexikon/jive', component: LoaderComponent, data: {path: 'tanzlexikon/jive'}},
  {path: 'tanzlexikon/paso-doble', component: LoaderComponent, data: {path: 'tanzlexikon/paso-doble'}},
  {path: 'tanzlexikon/blues', component: LoaderComponent, data: {path: 'tanzlexikon/blues'}},

  // legal
  {path: 'widerrufsrecht', component: LoaderComponent, data: {path: 'legal/widerrufsrecht'}},
  {path: 'zahlungskonditionen', component: LoaderComponent, data: {path: 'legal/zahlungskonditionen'}},
  {path: 'ruecksendungen-und-versandkosten', component: LoaderComponent, data: {path: 'legal/ruecksendungen-und-versandkosten'}},
  {path: 'agb', component: LoaderComponent, data: {path: 'legal/agb'}},
  {path: 'datenschutz', component: LoaderComponent, data: {path: 'legal/datenschutz'}},
  {path: 'impressum', component: LoaderComponent, data: {path: 'legal/impressum'}},

  // ENGLISH
  // basic
  {path: 'en', component: LandingComponent},
  {path: 'en/a', component: AffiliateComponent, data: {navbar: false}},
  {path: 'en/a/:code', component: AffiliateComponent, data: {navbar: false}},

  // info
  {path: 'en/faq', component: LoaderComponent, data: {path: 'en/faq'}},
  {path: 'en/foot-widths', component: LoaderComponent, data: {path: 'en/foot-widths'}},
  {path: 'en/lexicon-of-dances', component: LoaderComponent, data: {path: 'en/lexicon-of-dances'}},
  {path: 'en/lexicon-of-dances/the-slow-waltz', component: LoaderComponent, data: {path: 'en/lexicon-of-dances/the-slow-waltz'}},
  {path: 'en/lexicon-of-dances/the-viennese-waltz', component: LoaderComponent, data: {path: 'en/lexicon-of-dances/the-viennese-waltz'}},
  {path: 'en/lexicon-of-dances/the-tango', component: LoaderComponent, data: {path: 'en/lexicon-of-dances/the-tango'}},
  {path: 'en/lexicon-of-dances/the-slow-foxtrot', component: LoaderComponent, data: {path: 'en/lexicon-of-dances/the-slow-foxtrot'}},
  {path: 'en/lexicon-of-dances/the-quickstep', component: LoaderComponent, data: {path: 'en/lexicon-of-dances/the-quickstep'}},
  {path: 'en/lexicon-of-dances/the-rumba', component: LoaderComponent, data: {path: 'en/lexicon-of-dances/the-rumba'}},
  {path: 'en/lexicon-of-dances/the-samba', component: LoaderComponent, data: {path: 'en/lexicon-of-dances/the-samba'}},
  {path: 'en/lexicon-of-dances/the-cha-cha-cha', component: LoaderComponent, data: {path: 'en/lexicon-of-dances/the-cha-cha-cha'}},
  {path: 'en/lexicon-of-dances/the-jive', component: LoaderComponent, data: {path: 'en/lexicon-of-dances/the-jive'}},
  {path: 'en/lexicon-of-dances/the-paso-doble', component: LoaderComponent, data: {path: 'en/lexicon-of-dances/the-paso-doble'}},
  {path: 'en/lexicon-of-dances/the-blues', component: LoaderComponent, data: {path: 'en/lexicon-of-dances/the-blues'}},

  // legal
  {path: 'en/right-of-withdrawal', component: LoaderComponent, data: {path: 'en/legal/right-of-withdrawal'}},
  {path: 'en/terms-of-payment', component: LoaderComponent, data: {path: 'en/legal/terms-of-payment'}},
  {path: 'en/returns-and-shipping-costs', component: LoaderComponent, data: {path: 'en/legal/returns-and-shipping-costs'}},
  {path: 'en/terms-and-conditions', component: LoaderComponent, data: {path: 'en/legal/terms-and-conditions'}},
  {path: 'en/privacy', component: LoaderComponent, data: {path: 'en/legal/privacy'}},
  {path: 'en/imprint', component: LoaderComponent, data: {path: 'en/legal/imprint'}},

  // Highlights
  {path: 'highlights', loadChildren: () => import('./highlights/highlights.module').then(m => m.HighlightsModule)},
  {path: 'en/highlights', loadChildren: () => import('./highlights/highlights.module').then(m => m.HighlightsModule)},

  // {path: 'masken', loadChildren: () => import('./masks/masks.module').then(m => m.MasksModule)},
  // {path: 'en/masks', loadChildren: () => import('./masks/masks.module').then(m => m.MasksModule)},

  // User
  {path: '', loadChildren: () => import('./user/user.module').then(m => m.UserModule)},

  // Cart
  {path: '', loadChildren: () => import('./cart/cart.module').then(m => m.CartModule)},

  {path: 'blog', loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)},
  {path: 'en/blog', loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)},

  // Categories and Products
  {path: '', loadChildren: () => import('./search/search.module').then(m => m.SearchModule)},

  // Dynamic Pages
  {path: '', loadChildren: () => import('./dynamic-pages/dynamic-pages.module').then(m => m.DynamicPagesModule)},

  // escape
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
