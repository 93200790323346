import {AfterViewInit, Directive, ElementRef, EventEmitter, Output} from '@angular/core';
import {UserAgentService} from './user-agent.service';

declare let window: any;

@Directive({
  selector: '[appVisible]'
})
export class VisibleDirective implements AfterViewInit {

  @Output() onVisible = new EventEmitter();
  private observer: IntersectionObserver;

  constructor(private element: ElementRef, private ua: UserAgentService) {
  }

  ngAfterViewInit() {
    if (this.intersectionObserverSupported() && !this.ua.isPrerender()) {
      this.observer = new IntersectionObserver(entries => {
        this.checkForIntersection(entries);
      }, {});
      this.observer.observe(this.element.nativeElement);
    } else {
      this.onVisible.emit();
    }
  }

  private checkForIntersection = (entries: Array<IntersectionObserverEntry>) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (this.checkIfIntersecting(entry)) {
        this.onVisible.emit();
        this.observer.unobserve(<Element>(this.element.nativeElement));
        this.observer.disconnect();
      }
    });
  };

  private checkIfIntersecting(entry: IntersectionObserverEntry) {
    return (<any>entry).isIntersecting && entry.target === this.element.nativeElement;
  }

  private intersectionObserverSupported() {
    return !this.ua.isLegacy() && this.hasIntersectionObserver();
  }

  private hasIntersectionObserver() {
    return ('IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype);
  }
}
