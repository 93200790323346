import { Injectable } from '@angular/core';
import { LanguageService } from '../../../language.service';
import AppCountries from './countries';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  public countries = AppCountries;

  public countriesObj = {};

  constructor(private ls: LanguageService) {
    this.countriesObj['de'] = this.generateCountries('de');
    this.countriesObj['en'] = this.generateCountries('en');
  }

  getCountries(lang: string) {
    return this.countriesObj[lang];
  }

  generateCountries(lang: string) {
    const countries = [];
    for (const countryCode in this.countries) {
      countries.push({ code: countryCode, name: this.countries[countryCode][lang] });
    }
    return countries;
  }

  isGermany(country: string) {
    return country === "de";
  }

  isEu(country: string) {
    return country
      ? !this.isGermany(country) && this.countries[country].eu
      : undefined;
  }

  isEurope(country: string) {
    return country ? this.countries[country].europe : undefined;
  }
}
