import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Product} from '../../models/product';
import {SeoService} from '../seo.service';
import {routes, text} from './landing.i18n';
import {LanguageService} from '../language.service';
import {Meta} from '@angular/platform-browser';
import {ToastService} from '../shared/toast/toast.service';
import {DirectusService} from '../directus.service';

declare let google: any;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  public text = text;
  public routes = routes;
  public email: string;
  public mapVisible = false;

  public showcaseProducts: Product[];
  lat = 50.137598;
  lng = 8.509762;

  orgaStructuredData = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    'name': 'tanzschuhe24',
    'url': 'https://tanzschuhe24.de/',
    'logo': 'https://tanzschuhe24.de/assets/logo.png',
    'sameAs': [
      'https://www.instagram.com/tanzschuhe24.de/',
      'https://www.facebook.com/tanzschuhe24',
      'https://twitter.com/tanzschuhe24_de'
    ],
    'contactPoint': [
      {
        '@type': 'ContactPoint',
        'contactOption': 'TollFree',
        'telephone': '+49-(0)6196-594544',
        'contactType': 'customer service'
      }
    ]
  };

  websiteStructuredData = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    'url': 'https://tanzschuhe24.de/',
    'potentialAction': {
      '@type': 'SearchAction',
      'target': 'https://tanzschuhe24.de/suche/{search_term_string}',
      'query-input': 'required name=search_term_string'
    }
  };

  public style = [
    {
      'stylers': [
        {
          'hue': '#b02328'
        }
      ]
    },
    {
      'featureType': 'road',
      'elementType': 'labels',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'road',
      'elementType': 'geometry',
      'stylers': [
        {
          'lightness': 100
        },
        {
          'visibility': 'simplified'
        }
      ]
    }
  ];

  openingHours: any;
  constructor(private http: HttpClient, private seo: SeoService, public ls: LanguageService, private ts: ToastService, private meta: Meta, private directus: DirectusService) {
  }

  ngOnInit() {
    this.seo.setTitle(this.ls.t(this.text.seoTitle));
    this.seo.setDescription(this.ls.t(this.text.seoDescription));
    this.http.get('/api/products/interesting/141-058-400')
      .subscribe(
        products => this.showcaseProducts = <Product[]>products,
        error => console.error
      );
    this.loadOpeningTimes();
  }

  showMap() {
    this.mapVisible = true;
  }

  subscribeNewsletter(email: string) {
    this.http.post('/api/mail/newsletter/subscribe', {
      email: email,
      lang: this.ls.l
    })
      .subscribe(
        res => {
          this.ts.spawnToast(this.text.newsletterSubscribtionSuccess[this.ls.l]);
        },
        err => {
          this.ts.spawnToast(this.text.newsletterSubscribtionError[this.ls.l]);
        }
      );
  }

  loadOpeningTimes() {
    this.directus.loadOpeningTimes().subscribe(
      res => {
        this.openingHours = res;
      }
    );
  }
}
