export class Product {
  private _id: number;
  private _articleNumber: string;
  private _name: string;
  private _price: number;
  private _categories: string[];
  private _sizeType: string;
  private _sizes: number[];
  private _properties: any;
  private _title: string;
  private _description: string;
  private _url: string;
  private _groups: string[];

  private _amount: number;
  private _size: number;

  constructor(data?: any) {
    this.amount = 1;
    this.price = 0;

    if (data !== undefined && data.id !== undefined) {
      this.id = data.id;
      this.articleNumber = data.articleNumber;
      this.name = data.name;
      this.price = data.price;
      this.categories = data.categories;
      this.sizeType = data.sizeType;
      this.sizes = data.sizes;
      this.properties = data.properties;
      this.title = data.title;
      this.description = data.description;
      this.url = data.url;
      this.groups = data.groups;

      if (data._amount !== undefined) {
        this.amount = data.amount;
        this.size = data.size;
      }
    } else if (data !== undefined && data._id !== undefined) {
      this.id = data._id;
      this.articleNumber = data._articleNumber;
      this.name = data._name;
      this.price = data._price;
      this.categories = data._categories;
      this.sizeType = data._sizeType;
      this.sizes = data._sizes;
      this.properties = data._properties;
      this.title = data._title;
      this.description = data._description;
      this.url = data._url;
      this.groups = data._groups;

      if (data._amount !== undefined) {
        this.amount = data._amount;
        this.size = data._size;
      }
    }
  }
  /**
   * Transforms a price into a EUR-String
   * @param price
   * @returns {number}
   */
  private moneyToString(price: number): string {
    const tokens = price.toString().split('.');
    if (tokens.length > 1) {
      const decimal = tokens[1].substr(0, 2);
      if (decimal.length === 1) {
        return tokens[0] + ',' + decimal + '0 €';
      } else {
        return tokens[0] + ',' + decimal + ' €';
      }
    } else {
      return tokens[0] + ',' + '00 €';
    }
  }

  // GETTERS AND SETTERS
  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get articleNumber(): string {
    return this._articleNumber;
  }

  set articleNumber(value: string) {
    this._articleNumber = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get categories(): string[] {
    return this._categories;
  }

  set categories(value: string[]) {
    this._categories = value;
  }

  get sizeType(): string {
    return this._sizeType;
  }

  set sizeType(value: string) {
    this._sizeType = value;
  }

  get properties(): any {
    return this._properties;
  }

  set properties(value: any) {
    this._properties = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get url(): string {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }

  get groups(): string[] {
    return this._groups;
  }

  set groups(value: string[]) {
    this._groups = value;
  }


  set amount(amount: number) {
    this._amount = amount;
  }

  get amount() {
    return this._amount;
  }

  set price(price: number) {
    this._price = price;
  }

  get price() {
    if (this.amount) {
      return this._price * this.amount;
    }
    return this._price;
  }

  get noVatPrice() {
    if (this.amount) {
      return (this._price * this.amount) / 1.19;
    }
    return this._price / 1.19;
  }

  set size(size: number) {
    this._size = size;
  }

  get size() {
    return this._size;
  }

  set sizes(sizes: number[]) {
    this._sizes = sizes;
  }

  get sizes() {
    return this._sizes;
  }

  get pricePieceString() {
    return this.moneyToString(this.price);
  }

  get priceSumString() {
    return this.moneyToString(this.price);
  }

}
