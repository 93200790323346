import {Injectable} from '@angular/core';
import { Router } from '@angular/router';
import {CountryService} from '../../user/dashboard/account-info/country.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import { CartService } from '../cart.service';
import { LanguageService } from '../../language.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  public user: any;
  public paymentMethod: string;

  constructor(private router: Router, private ls: LanguageService, private cs: CountryService, private http: HttpClient, private cartService: CartService) {
  }


  async getShippingCosts(price): Promise<number> {
    const shipping: any =
      await this.http.get('api/payments/shipping', {params: new HttpParams().set('price', price)}).toPromise();
    return shipping.cost;
  }

  async getUst(): Promise<boolean> {
    const ust: any =
      await this.http.get('/api/ust').toPromise();
    return ust.ust;
  }

  piaEligible() {
    const country = this.user.billing.country;

    if (this.cs.isGermany(country) || this.cs.isEu(country)) {
      return true;
    } else {
      return false;
    }
  }

  invoiceEligible() {
    const country = this.cs.isGermany(this.user.billing?.country);
    const containsUnavailable = this.cartService.products?.some((p: any) => p.sizes[+p.size]?.status >= 3);
    const isMobile = ('ontouchstart' in window || navigator.maxTouchPoints > 0) && (window.innerWidth < 768 || screen.width < 768);
    const isUnderPriceLimit = this.cartService.price <= 2500;

    return {
      eligible: country && !containsUnavailable && !isMobile && isUnderPriceLimit,
      criteria: {
        country,
        noUnavailable: !containsUnavailable,
        desktop: !isMobile,
        underLimit: isUnderPriceLimit,
      }
    };
  }

  allowInvoiceCheckout() {
    const dobValid = this.user.dateOfBirth?.trim();
    const phoneValid = this.user.phone?.trim();

    if (!dobValid || !phoneValid) {
      this.router.navigate([this.ls.rl + 'dashboard'], { queryParams: { dobValid: !!dobValid, phoneValid: !!phoneValid } });
    }
  }

  get discountFactor() {
    if (this.user !== undefined && this.user.discount !== undefined) {
      return 1 - this.user.discount / 100;
    }
    return 1;
  }

}
