<div class="product-bar" *ngIf="products !== undefined">
  <div class="control-left" (click)="scroll(-1)" [ngClass]="{disabled: !canScroll(-1)}">
    <div class="control"><app-icon>keyboard_arrow_left</app-icon></div>
  </div>
  <div class="items" #items>
    <app-item *ngFor="let product of products" [content]="product"></app-item>
  </div>
  <div class="control-right" (click)="scroll(1)" [ngClass]="{disabled: !canScroll(1)}">
    <div class="control"><app-icon>keyboard_arrow_right</app-icon></div>
  </div>
</div>
