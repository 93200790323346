export const routes = {
  headC2A: {
    de: 'tanzschuhe',
    en: 'dance-shoes'
  },
  cardLadies: {
    de: 'damen',
    en: 'ladies'
  },
  cardMens: {
    de: 'herren',
    en: 'mens'
  },
  cardLadiesDancewear: {
    de: 'damen/tanzkleidung',
    en: 'ladies/dancewear'
  },
  cardMensDancewear: {
    de: 'herren/tanzkleidung',
    en: 'mens/dancewear'
  },
  store: {
    de: 'ladengeschaeft',
    en: 'ladengeschaeft'
  },
  highlights: {
    de: 'highlights/damen',
    en: 'highlights/ladies'
  }
};

export const text = {
  seoTitle: {
    de: 'Tanzschuhe von Diamant - Riesige Auswahl - Online Bestellen',
    en: 'Diamant Dancing Shoes - International Shipping'
  },
  seoDescription: {
    de: 'Tanzschuhe für Herren und Damen | Blitzschneller Versand ✔ Kostenlose Retouren ✔ Über 200 Lagermodelle ✔ Reparaturservice ✔ Diamant® Tanzschuhe',
    en: 'Dancing Shoes for Men and Women ✔ Flexible and Comfortable ✔ Latin, Standard, Salsa & Tango | Buy Online now | Diamant®'
  },
  headPreTitle: {
    de: 'Diamant Tanzschuhe Online-Shop seit 1998',
    en: 'Diamant Dance Shoes Online-Shop since 1998'
  },
  headTitle: {
    de: 'Tanzschuhe Made in Germany',
    en: 'Dance Shoes Made in Germany'
  },
  headText: {
    de: `<p>Wir sind ein Online-Shop für Tanzschuhe und Tanzsportbedarf und führen seit 1998 aus Überzeugung ausschließlich deutsche Markenprodukte der <a target="_blank" rel="noopener" href="https://www.diamant.net">Diamant Tanzschuhmanufaktur</a>. Die Firma Diamant fertigt Tanzschuhe als einziger Hersteller in Deutschland und steht für hervorragende Qualität, geprüfte Materialien und erstklassigen Service und dies zu einem fairen Preis-Leistungsverhältnis.`,
    en: `<p>We are an Online-Shop for dance shoes and dance sport supplies. Since 1998 we sell German brand products manufactured by the German brand <a target="_blank" rel="noopener" href="https://www.diamant.net">Diamant Dance Shoes</a>. The company Diamant manufactures dance shoes as the only manufacturer in Germany and stands for excellent quality, tested materials and first-class service and this at a fair price-performance ratio.`
  },
  headC2A: {
    de: 'Tanzschuhe ansehen',
    en: 'See our dance shoe range'
  },
  headImg: {
    de: 'Latein Damen Tanzschuh Mod. 108 in silber',
    en: 'Latin Dance Shoe Mod. 108 silver'
  },
  cardLadies: {
    de: 'Damen',
    en: 'Ladies'
  },
  cardMens: {
    de: 'Herren',
    en: 'Mens'
  },
  cardMensDancewear: {
    de: 'Tanzkleidung Herren',
    en: 'Mens Dancewear'
  },
  cardLadiesDancewear: {
    de: 'Tanzkleidung Damen',
    en: 'Ladies Dancewear'
  },
  newsletterTitle: {
    de: 'Damen Highlights',
    en: 'Ladies\' Highlights'
  },
  newsletterText: {
    de: 'Sind sie auf der Suche nach Damen-Tanzschuhen? Dann lassen sie sich auf unserer neuen Highlights-Seite inspirieren und entdecken sie die neusten Angebote der Diamant Tanzschuh-Welt.',
    en: 'Are you looking for ladies dancing shoes? Then let yourself be inspired on our new Highlights page and discover the latest offers from the Diamant dance shoe world.'
  },
  newsletterInputPlaceholder: {
    de: 'Tanzschuhe entdecken',
    en: 'Ladies\' Highlights'
  },
  newsletterInputC2A: {
    de: 'Tanzschuhe entdecken',
    en: 'Ladies\' Highlights'
  },
  newsletterSubscribtionSuccess: {
    de: 'Newsletter erfolgreich abonniert.',
    en: 'You subscribed to our newsletter. Thanks!'
  },
  newsletterSubscribtionError: {
    de: 'Etwas ist schiefgelaufen. Bitte versuchen sie es später erneut.',
    en: 'Something went wrong. Please try again later.'
  },
  topsellerTitle: {
    de: 'Diamant Topseller',
    en: 'Diamant topsellers'
  },
  visitUsTitle: {
    de: 'Besuchen sie uns!',
    en: 'Come visit us!'
  },
  winterBreakTitle: {
    de: 'Winterpause 24.12. - 01.01.2019',
    en: 'Winter break 24.12. - 01.01.2019'
  },
  winterBeakText: {
    de: 'Unser Ladengeschäft in der Königsteiner Straße 22 in Bad Soden am Taunus bleibt für den Zeitraum vom 24.12.2018 bis einschließlich dem 01.01.2019 geschlossen.',
    en: 'Our shop in Königsteiner Straße 22 in Bad Soden am Taunus will be closed for the period from 24.12.2018 up to and including 01.01.2019.'
  },
  visitUsText: {
    de: `Kommen Sie zu uns nach Bad Soden und wählen aus über 100 Modellen Ihre passenden Tanzschuhe aus. Weitere Informationen finden sie <a href="/${routes.store.de}">hier</a>.`,
    en: `Come visit us in Bad Soden and choose from over 100 models. More information <a href="/${routes.store.en}">here</a>.`
  },
  moreInfoTitle: {
    de: 'Tanzschuhe24 - Dance Art direct - Weltweiter Versand von Tanzschuhen seit 1998',
    en: 'Dance Shoes for ballroom dancing, latin dancers, for Salsa & Tango Nights, Bridal Shoes and Organ Shoes\n'
  },
  moreInfoText: {
    de: `
        <p>Wir freuen uns über Ihren Besuch in unserem Tanzschuhe-Online-Shop! Hier sind Sie richtig wenn Sie hochwertige Produkte aus deutscher Herstellung zu fairen Preisen suchen. Für alle bekannten Gesellschaftstänze in den Kategorien Standard wie etwa dem Wiener Walzer, Foxtrott, Quickstep oder Tango sowie für Lateintänze wie beispielsweise der Rumba, dem Cha-Cha-Cha oder dem Paso Doble finden Sie hier das richtige Schuhwerk für Damen und Herren. Ganz gleich ob Sie Anfänger in der Tanzschule oder eine fortgeschrittene Tänzerin einer Formation sind, wir haben Schuhe für jeden Anspruch im Angebot. Das passende Zubehör und Pflegemittel bieten wir selbstverständlich ebenfalls an.</p>
        <h3>Brauche ich denn überhaupt richtige Tanzschuhe?</h3>
        <p>Braucht ein Fußballer denn unbedingt Fußballschuhe um Fußball zu spielen? Die Antwort lautet: Nein, nicht unbedingt. Aber macht der Ballsport mit den richtigen Fußballschuhen mehr Spaß und geht es besser? Selbstverständlich! Tanzschuhe wurden speziell für die Anforderung dieses wunderschönen Sports entwickelt und sind echte Spezialschuhe. Der gesamte Schuhaufbau und die Machart sind auf Leichtigkeit, Flexibilität und Robustheit ausgelegt. Zudem müssen Tanzschuhe auf dem gewachsten oder geölten Parkett besonders gut gleiten und bei Drehungen und Figuren, wie Sie zum Beispiel im Fernsehen bei "Let's Dance" sehen können, den nötigen Halt bieten. Diese Eigenschaft erlangen Tanzschuhe durch die spezielle Rauledersohle und einen optimierten Absatz. Alle bei uns erhältlichen Artikel der Marke Diamant haben zudem selbstverständlich eine perfekte Passform und bieten den höchsten Tragekomfort.</p>
        <h3>Welche Gründe sprechen für einen Einkauf bei tanzschuhe24?</h3>
        <p>Wir haben mit dem Versand von Tanzschuhen im Jahre 1998 begonnen als der Online-Versand noch in den Kinderschuhen gesteckt hat und Amazon, ebay und Zalando noch niemand kannte. Wir haben über diese lange Zeitspanne viele Erfahrungen sammeln können und haben unseren Fokus immer auf dem Spezialprodukt Tanzschuhe gerichtet. Wir arbeiten mit unserem Lieferanten, der <a href="https://www.diamant.net" target="_blank">Diamant Tanzschuhmanufaktur</a> seit Anfang an zusammen und sind überzeugt von Produkten aus deutscher Herstellung. Hier sind die 5 Gründe warum Sie bei uns Ihre Tanzschuhe shoppen sollten:</p>
        <ul>
          <li>Deutsches Unternehmen mit Sitz in Bad Soden am Taunus</li>
          <li>20 Jahre Erfahrung im Online-Versand</li>
          <li>Sichere Bezahlung, schneller Versand binnen 24 Stunden</li>
          <li>Toller Service durch Fachkräfte per Telefon, Chat oder Email</li>
          <li>Markenprodukte MADE IN GERMANY</li>
        </ul>
        <p>Haben wir Sie überzeugen können? Dann sehen Sie sich unsere Tanzschuhe für Damen order Herren doch einmal genauer an oder lesen Sie unseren <a href="https://tanzschuhe24.de/blog/">Tanzschuhe-Blog</a>.</p>
`,
    en: `<p>You are looking for <a href="/en/ladies/latin">Dance Shoes for Latin Dancers</a> or <a href="/en/ladies/standard">Ladies Standard Dance Shoes</a>, <a href="/en/ladies/showdance">Ladies Showdance</a> to have more fun while dancing? Or you want to use our shoes as Bridal Shoes or Organ Shoes?</p>`
  }
};


