import {Component, OnInit} from '@angular/core';
import {OverlayService} from './overlay.service';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {

  constructor(public os: OverlayService) { }

  ngOnInit() {
  }

}
