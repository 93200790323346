<div ngbDropdown [autoClose]="'outside'" class="d-inline-block dropdown">
  <button class="btn" ngbDropdownToggle>
    <ng-content></ng-content>
    <!---->
  </button>
  <div ngbDropdownMenu class="dropdown-menu">
    <button class="dropdown-item" *ngFor="let item of filterContent" (click)="fs.toggleFilter(type, item)"
      [ngClass]="{'active': fs.activeFilters[type]?.includes(item)}">
      <input type="checkbox" [ngModel]="fs.activeFilters[type]?.includes(item)">
      <div class="option">
        <span>{{item[ls.l]}}</span>
        <div class="color-square" *ngIf="type === 'color' && getColors(item[ls.l])?.length == 1"
          [ngStyle]="{'background-color': colorCodes[getColors(item[ls.l])[0]]}"></div>
        <div class="color-square" *ngIf="type === 'color' && getColors(item[ls.l])?.length == 2"
          [ngStyle]="{'border-top': '1em solid', 'border-top-color': colorCodes[getColors(item[ls.l])[0]], 'border-right': '1em solid', 'border-right-color': colorCodes[getColors(item[ls.l])[1]]}">
        </div>
        <div class="color-square"
          *ngIf="type === 'color' && (item[ls.l] === 'Schwarz-Multicolor' || item[ls.l] === 'Black-Multicolor')"
          [ngStyle]="{'background-color': 'dimgrey', 'background-image': 'linear-gradient(to bottom right, black, purple, black, black, pink, black, black, cyan, black, black, yellow, black, black, red, black)'}">
        </div>
      </div>
    </button>
  </div>
</div>