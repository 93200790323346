import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private http: HttpClient) {
  }

  updateUser(user: any) {
    return this.http.put('/auth/account', user);
  }

  updateCart(user: any) {
    return this.http.put('/auth/cart', user);
  }
}
