import {AfterViewInit, Component, EventEmitter, Output} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AnalyticsService} from '../../analytics.service';
import {Router} from '@angular/router';
import {CheckoutService} from '../../cart/checkout/checkout.service';
import {LanguageService} from '../../language.service';
import {CartService} from '../../cart/cart.service';
import {ToastService} from '../toast/toast.service';
import {AffiliateService} from '../../affiliate/affiliate.service';

declare let paypal;

@Component({
  selector: 'app-paypal-express-button',
  templateUrl: './paypal-express-button.component.html',
  styleUrls: ['./paypal-express-button.component.scss']
})
export class PaypalExpressButtonComponent implements AfterViewInit {
  @Output()
  clickTriggered = new EventEmitter();

  public text = {
    failedToast: {
      de: 'Bezahlung fehlgeschlagen. Bitte versuchen sie es erneut.',
      en: 'Payment failed. Please try again.'
    }
  };

  private paypalPaymentID;

  constructor(public affiliateService: AffiliateService, public ls: LanguageService, public cs: CartService, private http: HttpClient, private as: AnalyticsService, private router: Router, private chs: CheckoutService, private ts: ToastService) {
    this.loadScript();
  }

  ngAfterViewInit() {
    this.tryRenderPayPal();
  }

  tryRenderPayPal() {
    if (typeof paypal === 'undefined') {
      setTimeout(() => {
        this.tryRenderPayPal();
      }, 50);
    } else {
      this.renderPayPalButton();
    }
  }

  renderPayPalButton() {
    paypal.Button.render({

      env: (environment.production) ? 'production' : 'sandbox', // sandbox | production

      // Show the buyer a 'Pay Now' button in the checkout flow
      commit: true,
      locale: this.ls.l === 'de' ? 'de_DE' : 'en_US',
      style: {
        shape: 'rect',
        size: 'responsive',
        label: 'checkout',
        tagline: false,
      },

      // payment() is called when the button is clicked
      payment: (data, actions) => {
        this.clickTriggered.emit();
        const CREATE_URL = '/api/payments/paypal-express';
        // Make a call to your server to set up the payment
        const affiliate = localStorage.getItem('affiliate');
        return paypal.request.post(CREATE_URL, {cart: JSON.stringify(this.cs.products), code: affiliate})
          .then((res) => {
            this.paypalPaymentID = res.paymentID;
            return this.paypalPaymentID;
          });
      },

      onAuthorize: (data, actions) => {
        // Make a call to the REST api to execute the payment
        return actions.payment.execute().then(() => {
          this.http.post('/api/payments/paypal-express/after-widget', { paymentID: data.paymentID, success: true }).subscribe(
            res => {
              this.handlePaymentResponse(res);
            },
            err => {
              this.handlePaymentResponse({ success: false });
            }
          );
        });
      },

      onCancel: (data, actions) => {
        this.http.post('/api/payments/paypal-express/after-widget', { paymentID: data.paymentID, success: false }).subscribe(
          res => {
            this.handlePaymentResponse({ success: false });
          },
          err => {
            this.handlePaymentResponse({ success: false });
          });
      },

      onError: (data, actions) => {
        this.http.post('/api/payments/paypal-express/after-widget', { paymentID: data.paymentID, success: false }).subscribe(
          res => {
            this.handlePaymentResponse({ success: false });
          },
          err => {
            this.handlePaymentResponse({ success: false });
          });
      }

    }, '#paypal-button-container');
  }

  private handlePaymentResponse(res) {
    if (res.success === true) {
      this.as.purchase(this.cs.products, Date.now().toString(), this.cs.price * this.chs.discountFactor, 0, this.cs.price - this.cs.noVatPrice);
      this.cs.products = [];
      this.router.navigate([this.ls.rl + 'thank-you'], { queryParams: res.merchantData });
    } else {
      this.ts.spawnToast(this.ls.t(this.text.failedToast), 5000);
      this.as.checkoutFailed();
    }
  }

  private loadScript() {
    const scripts = [];
    if (typeof paypal === 'undefined') {
      scripts.push('https://www.paypalobjects.com/api/checkout.js');
    }
    for (const script of scripts) {
      const node = document.createElement('script');
      node.src = script;
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }


}
