import { text } from '../../shared/usps.i18n';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageService } from '../../language.service';

@Component({
  selector: 'app-usp-slider',
  templateUrl: './usp-slider.component.html',
  styleUrls: ['./usp-slider.component.scss']
})
export class UspSliderComponent implements OnInit, OnDestroy {

  public text = text;

  index = 0;
  interval;

  constructor(public langS: LanguageService) {
  }

  ngOnInit() {
    this.interval = setInterval(() => {
      if (this.index < 2) {
        this.index++;
      } else {
        this.index = 0;
      }
    }, 4750);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

}
