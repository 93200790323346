import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService {
  constructor(private http: HttpClient) {
  }

  checkAffiliateCode(code: string): Observable<{ code: string, isValid: boolean }> {
    return this.http.post<any>(`/api/affiliate-code/validate`, {code});
  }

  get codeActive(): boolean {
    return !!localStorage.getItem('affiliate');
  }
}
