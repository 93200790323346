<div class="scanner" appVisible (onVisible)="loadImage()"></div>

<a
  [routerLink]="getLink"
  appVisible
  (onVisible)="loadImage()"
  (click)="click()"
>
  <div class="item">
    <div class="product-image">
      <cl-image
        public-id="{{ getProductNumber }}/main"
        [attr.alt]="content.title[ls.l]"
        *ngIf="enteredViewport"
        class="img-responsive"
        fetch_format="auto"
      >
        <cl-transformation width="400" crop="fill"></cl-transformation>
        <cl-transformation
          quality="auto:eco"
          attr.dpr="{{ devicePixelRatio }}"
        ></cl-transformation>
      </cl-image>
    </div>
    <div class="description">
      <div class="info">
        <span class="mod-no" *ngIf="!isNonShoeArticle">{{ content.name }}</span>
        <span class="accessory-title" *ngIf="isNonShoeArticle">{{
          content.title[ls.l]
        }}</span>
        <span class="heel">{{ getShortDescription }}</span>
      </div>
      <hr class="divider" />
      <p class="price">
        {{ content.price | currency : "EUR" : "symbol" : "1.2-2" : ls.l }}
        <span *ngIf="isMaterial">{{ text.squareMeter[ls.l] }}</span>
      </p>
    </div>
  </div>
</a>
