<app-usp-slider></app-usp-slider>
<div class="overlay fixed-top" *ngIf="os.categoriesOverlay" [ngClass]="{'hide': (os.categoriesOverlay && os.hideOverlay)}">
  <div class="container">
    <div class="row">
      <app-categories></app-categories>
      <div class="backdrop" (click)="os.hideCategoriesOverlay()">
      </div>
    </div>
  </div>
</div>
