export enum Status {
  PUBLISHED = "published",
  DRAFT = "draft",
  DELETED = "deleted",
}

export enum Language {
  DE = "de",
  EN = "en",
}

export enum Page {
  DIAMANT = 'Diamant',
  TS24 = 'Tanzschuhe24',
}