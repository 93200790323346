import {Injectable} from '@angular/core';
import {LoginService} from '../user/login/login.service';
import {DashboardService} from '../user/dashboard/dashboard.service';
import {Product} from '../../models/product';
import {LanguageService} from '../language.service';
import {LocalStorageService} from '../local-storage.service';
import {AnalyticsService} from '../analytics.service';
import BroadcastChannel from 'broadcast-channel';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private $deliveryDate: Observable<any>;
  private _currentDeliveryDate: BehaviorSubject<any>;
  public _price: number;
  public _products: Product[] = [];
  private bc = new BroadcastChannel('cart_channel');
  public observ: Observable<any>;
  public minimumOrderValue = 10;
  public crossSellingProducts: Record<string, any>[] = [];

  constructor(private storage: LocalStorageService, private http: HttpClient, private ls: LoginService, private ds: DashboardService, private langS: LanguageService, private as: AnalyticsService) {
    this.loadCart();
    this.bc.addEventListener('message', () => {
      this.products = <Product[]>this.storage.retrieve('cart');
    });

    // Get delivery date
    this._currentDeliveryDate = new BehaviorSubject<any>(storage.retrieve('deliverydate'));
    this.$deliveryDate = this._currentDeliveryDate.asObservable();
    if (environment.production) {
      this.remoteDeliveryDate();
    }
  }


  private remoteDeliveryDate() {
    this.http.get('https://www.diamant.net/api/shop/deliverydate').subscribe(
      date => {
        this._currentDeliveryDate.next(date);
        this.storage.store('deliverydate', date);
      });
  }

  getDeliveryDate() {
    return this.$deliveryDate.pipe(distinctUntilChanged());
  }
  /**
   * Request API for user cart
   */
  public loadCart() {
    this.ls.getAccountInfo()
      .subscribe(
        user => this.handleCartLoading(user),
        err => console.error(err)
      );
  }

  /**
   * If user has online cart -> load it
   * Else load the local cart
   * @param user
   */
  private handleCartLoading(user) {
    if (user !== undefined) {
      this.loadOnline(user.cart);
    } else {
      this.loadLocal();
    }
  }

  /**
   * Load the local cart
   */
  private loadLocal() {
    if (!this.storage.retrieve('online')) {
      if (this.storage.retrieve('cart') !== null) {
        this.products = <Product[]>this.storage.retrieve('cart');
      }
    } else {
      this.unauth();
    }
  }

  /**
   * Load cart from DB
   * @param onlineCart
   */
  private loadOnline(onlineCart) {
    const isCartOnline = (this.storage.retrieve('online'));

    if (isCartOnline) {
      this.products = onlineCart;
    } else {
      this.products = this.storage.retrieve('cart');
      if (onlineCart !== null) {
        for (let i = 0; i < onlineCart.length; i++) {
          this.addToCart(onlineCart[i], onlineCart[i].amount);
        }
      }
      this.storage.store('online', true);
    }

    this.push();
  }

  /**
   * Saves the Cart to local storage and database
   */
  private push() {
    this.storage.store('cart', this.products);

    if (this.storage.retrieve('online')) {
      this.ds.updateCart({cart: this.products}).subscribe(
        success => true,
        err => this.unauth()
      );
    } else {
      this.storage.store('online', false);
    }
  }

  public unauth() {
    this.products = [];
    this.storage.store('cart', this.products);
    this.storage.store('online', false);

    // Notify other tabs / windows.
    this.bc.postMessage('cart changed');
  }


  /**
   * Adds a new product to cart or increases the amount if it already exists.
   * @param product
   * @param amount
   */
  public addToCart(product: any, amount: number = 1) {
    const productCopy = JSON.parse(JSON.stringify(product));

    if (productCopy.crossSellingProducts?.length > 0) {
      this.crossSellingProducts = productCopy.crossSellingProducts;
      delete productCopy.crossSellingProducts;
    }
    if (this.alreadyAdded(productCopy) > -1) {
      this.changeAmount(productCopy, this.products[this.alreadyAdded(productCopy)].amount + amount);
    } else {
      productCopy.amount = amount;
      this.as.addToCart(new Product(productCopy));
      this.addProduct(productCopy);
    }
    this.push();

    // Notify other tabs / windows.
    this.bc.postMessage('cart changed');

  }

  /**
   * Adds a the product (as object) to the products array
   * @param product
   */
  public addProduct(product: Product) {
    if (product.amount === undefined) {
      product.amount = 1;
    }
    this._products.push(new Product(product));
  }

  /**
   * Removes a product from the cart
   * @param product
   */
  public removeFromCart(product: Product) {
    if (this.alreadyAdded(product) > -1) {
      this.as.removeFromCart(new Product(product));
      this.products.splice(this.alreadyAdded(product), 1);
    }
    this.push();

    // Notify other tabs / windows.
    this.bc.postMessage('cart changed');
  }

  /**
   * Changes the amount of a product, adds it, if it doesn't exist or removes it if the amount is lower than 1
   * @param product
   * @param amount
   */
  public changeAmount(product: Product, amount: number) {
    if (amount < 1) {
      this.removeFromCart(product);
    } else if (this.alreadyAdded(product) > -1) {
      this.products[this.alreadyAdded(product)].amount = amount;
    } else {
      product.amount = amount;
      this.addProduct(product);
    }
    this.push();

    // Notify other tabs / windows.
    this.bc.postMessage('cart changed');
  }

  // Helper Functions
  /**
   * Calculates and updates the price of the cart's contents
   */
  private calculatePrice() {
    this._price = 0;
    for (let i = 0; i < this._products.length; i++) {
      this._price += this._products[i].price;
    }
  }

  /**
   * Checks if product was already added to cart [-1 = wasn't added; {number} = position of product]
   * @param product
   * @returns {number}
   */
  public alreadyAdded(product: Product) {
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].id === product.id && this.products[i].size === product.size) {
        return i;
      }
    }
    return -1;
  }

  /**
   * Transforms a price into a EUR-String
   * @param price
   * @returns {number}
   */
  public moneyToString(price: number): string {
    const tokens = price.toString().split('.');
    const divider = (this.langS.l === 'de') ? ',' : '.';
    if (tokens.length > 1) {
      const decimal = tokens[1].substr(0, 2);
      if (decimal.length === 1) {
        return tokens[0] + divider + decimal + '0 €';
      } else {
        return tokens[0] + divider + decimal + ' €';
      }
    } else {
      return tokens[0] + divider + '00 €';
    }
  }

  /**
   * Checks if price is above minimum Order Value
   */
  isMinimumOrderValue(additionalAmount?: number): boolean {
    let totalAmount = this.price;
    if (additionalAmount) {
      totalAmount += additionalAmount;
    }

    return totalAmount >= this.minimumOrderValue;
  }

  // GETTERS AND SETTERS
  /**
   * Adds passed products as Product-Objects
   * @param products
   */
  set products(products: Product[]) {
    this._products = [];
    if (products !== null && products.length > 0) {
      for (let i = 0; i < products.length; i++) {
        this.addProduct(products[i]);
      }
    } else {
      this._products = [];
    }
    this.push();
  }

  get products() {
    return this._products;
  }

  set price(price: number) {
    this._price = price;
  }

  get price() {
    this.calculatePrice();
    return this._price;
  }

  get noVatPrice() {
    this.calculatePrice();
    return this._price / 1.19;
  }

  get centPrice() {
    this.calculatePrice();
    return this._price * 100;
  }

  get priceString() {
    this.calculatePrice();
    return this.moneyToString(this.price);
  }

  get productCount() {
    return this.products.length;
  }
}
