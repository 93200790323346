import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class FilterService {
  public activeFilters = {
    width: [],
    heel: [],
    material: [],
    color: [],
  };

  public hasMatches = false;

  constructor(private router: Router, private route: ActivatedRoute) {}

  toggleFilter(type: string, content: any) {
    if (!this.activeFilters[type].includes(content)) {
      this.activeFilters[type].push(content);
    } else {
      this.activeFilters[type].splice(
        this.activeFilters[type].indexOf(content),
        1
      );
    }
  }

  removeFilters() {
    this.activeFilters.width = [];
    this.activeFilters.heel = [];
    this.activeFilters.material = [];
    this.activeFilters.color = [];
  }

  get hasFilters() {
    if (
      this.activeFilters.width.length > 0 ||
      this.activeFilters.heel.length > 0 ||
      this.activeFilters.material.length > 0 ||
      this.activeFilters.color.length > 0
    ) {
      return true;
    }
    return false;
  }

  filterCount() {
    return (
      this.activeFilters.width.length +
      this.activeFilters.heel.length +
      this.activeFilters.material.length +
      this.activeFilters.color.length
    );
  }

  matchesCriteria(properties: any) {
    if (this.hasFilters) {
      let matches = true;
      if (
        this.activeFilters.width.length > 0 &&
        !this.activeFilters.width.some((e) => e.de === properties.width.de)
      ) {
        matches = false;
      }
      if (
        this.activeFilters.heel.length > 0 &&
        !this.activeFilters.heel.some((e) => e.de === properties.heel.de)
      ) {
        matches = false;
      }
      if (
        this.activeFilters.material.length > 0 &&
        !this.activeFilters.material.some(
          (e) => e.de === properties.material.de
        )
      ) {
        matches = false;
      }
      if (
        this.activeFilters.color.length > 0 &&
        !this.activeFilters.color.some((e) => e.de === properties.color.de)
      ) {
        matches = false;
      }
      if (matches) {
        this.hasMatches = true;
      }
      return matches;
    }
    return true;
  }
}
