export const text = {
  new: {
    de: 'Neu',
    en: 'New'
  },
  sale: {
    de: 'Sale',
    en: 'Sale'
  },
  squareMeter: {
    de: 'pro qm',
    en: 'per sq m'
  }
};
