<div class="language-switcher" *ngIf="l.showSwitchDialog">
  <div class="language-switcher-inner">
    <div class="text">
      <h3 class="title">{{text.title | l}}</h3>
      <p class="description">{{text.description | l}}</p>
    </div>

    <div class="flags d-none d-lg-flex">
      <div class="flag">
        <img src="assets/language-flags/{{l.l}}.svg" (click)="l.saveLang(l.l)">
      </div>
      <app-icon>arrow_forward</app-icon>
      <div class="flag">
        <img src="assets/language-flags/en.svg" *ngIf="l.l === 'de'" (click)="l.saveLang('en', true)">
        <img src="assets/language-flags/de.svg" *ngIf="l.l === 'en'" (click)="l.saveLang('de', true)">
      </div>
    </div>

    <div class="actions" *ngIf="l.l === 'de'">
      <app-button [size]="'sm'" [text]="'Weiter auf Deutsch'" [style]="'outline'" (click)="l.saveLang('de', false)"></app-button>
      <app-button [size]="'sm'" [text]="'Switch to English'" (click)="l.saveLang('en', true)"></app-button>
    </div>
    <div class="actions" *ngIf="l.l === 'en'">
      <app-button [size]="'sm'" [text]="'Continue in English'" [style]="'outline'" (click)="l.saveLang('en', false)"></app-button>
      <app-button [size]="'sm'" [text]="'Weiter auf Deutsch'" (click)="l.saveLang('de', true)"></app-button>
    </div>
  </div>
</div>
