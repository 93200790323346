import { text as uspText, routes as uspRoutes } from '../usps.i18n';

export const text = {
  contact: {
    de: 'Kontakt',
    en: 'Contact'
  },
  phone: {
    de: 'Kontakt per Telefon',
    en: 'Contact us via Phone'
  },
  email: {
    de: 'Kontakt per E-Mail',
    en: 'Contact us via email'
  },
  information: {
    de: 'Informationen',
    en: 'Information'
  },
  i18nVersion: {
    de: 'English Version',
    en: 'Deutsche Version'
  },
  danceShoes: {
    de: 'Tanzschuhe',
    en: 'Dance Shoes'
  },
  faq: {
    de: 'Häufige Fragen',
    en: 'FAQ'
  },
  footWidth: {
    de: 'Fußweiten',
    en: 'Foot Widths'
  },
  lexiconOfDances: {
    de: 'Tanzlexikon',
    en: 'Lexicon of Dances'
  },
  rightOfWithdrawal: {
    de: 'Widerrufsrecht',
    en: 'Right of Withdrawal'
  },
  privacy: {
    de: 'Datenschutz',
    en: 'Privacy'
  },
  returnsAndShippingCosts: {
    de: 'Rücksendungen & Versandkosten',
    en: 'Returns & Shipping Costs'
  },
  termsConditions: {
    de: 'AGB',
    en: 'Terms & Conditions'
  },
  imprint: {
    de: 'Impressum',
    en: 'Imprint'
  },
  disclaimerA: {
    de: 'Alle Preise inkl. gesetzl. Mehrwertsteuer zzgl. ',
    en: 'All prices are quoted with included value-added tax and without '
  },
  disclaimerLink: {
    de: 'Versandkosten',
    en: 'shipping costs'
  },
  disclaimerB: {
    de: ' und ggf. Nachnahmegebühren, wenn nicht anders beschrieben.',
    en: ' and without possible delivery charges, if not otherwise described.'
  },
  disclaimerC: {
    de: 'Copyright © ' + new Date().getFullYear() + ' Dance Art direct | tanzschuhe24 | Alle Rechte vorbehalten',
    en: 'Copyright © ' + new Date().getFullYear() + ' Dance Art direct | tanzschuhe24 | All rights reserved.'
  },
  disclaimerShipping: {
    de: 'innerhalb Deutschlands',
    en: 'within Germany'
  },
  paymentOptions: {
    de: 'Zahlungsoptionen',
    en: 'Payment options'
  },
  shippingServices: {
    de: 'Versandanbieter',
    en: 'Shipping services'
  },
  service: {
    de: 'Unser Service',
    en: 'Our service'
  },
  returnPolicy: uspText.returnPolicy,
  madeInGermany: uspText.madeInGermany,
  freeReturns: uspText.freeReturns,
  pia: {
    de: 'VORKASSE',
    en: 'PIA'
  },
  rech: {
    de: 'RECHNUNG',
    en: 'INVOICE'
  }
};
export const routes = {
  i18nVersion: {
    de: 'en',
    en: ''
  },
  // Missbrauch dieses Services >:D
  i18nLang: {
    de: 'en',
    en: 'de'
  },
  danceShoes: {
    de: 'tanzschuhe',
    en: 'dance-shoes'
  },
  faq: {
    de: 'haeufige-fragen',
    en: 'faq'
  },
  footWidth: {
    de: 'fussweiten',
    en: 'foot-widths'
  },
  lexiconOfDances: {
    de: 'tanzlexikon',
    en: 'lexicon-of-dances'
  },
  rightOfWithdrawl: {
    de: 'widerrufsrecht',
    en: 'right-of-withdrawal'
  },
  returnsAndShippingCosts: uspRoutes.returnsAndShippingCosts,
  privacy: {
    de: 'datenschutz',
    en: 'privacy'
  },
  termsConditions: {
    de: 'agb',
    en: 'terms-and-conditions'
  },
  imprint: {
    de: 'impressum',
    en: 'imprint'
  }
};
